import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button } from "@mantine/core"
import { useAppDispatch } from "../../app/hooks";
import { updateCart } from "../../app/reducer/products.reducer"
import { CartItemData } from "../../assets/utility/dataTypes";
import './cart.css';


interface CartItemProps{
    data: CartItemData
}
  
export default function CartItem({data}:CartItemProps):JSX.Element {
    const dispatch = useAppDispatch()
    const { id, name, quantity, amountInCart, unit, price, priceAfterDiscount, deposit } = data;
  
    const addOneProduct = () => {
      dispatch(updateCart(data,'addone'))
    }
  
    const removeOneProduct = () => {
      dispatch(updateCart(data,'removeone'))
    }
  
    const deleteProduct = () => {
      dispatch(updateCart(data,'delete'))
    }
  
    return(
      <Card className="cart-item">
        <div className="cart-item-img">
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
            alt={"An image of "+name+". Property of Power Plant Foods."}
          />
        </div> 
        <div style={{width:'100%', paddingLeft:'10px'}}>
          <Card.Section className="cart-item-content">
            <div className="cart-item-data">
            <div className="cart-item-text">
              <div className="cart-item-title">
                {name}
              </div> 
              <div className="cart-item-subtitle">
                {quantity+unit}
              </div> 
            </div> 
            </div> 
            <div>
              <div className="cart-btn-wrapper">
                <Button onClick={()=>removeOneProduct()} style={{borderTopRightRadius:'0px', borderBottomRightRadius:'0px', height:'100%'}}>
                  <FontAwesomeIcon icon={faMinus}/>
                </Button>
                <div className="amountInCart-well">
                  {amountInCart}
                </div>
                <Button 
                // disabled={amountInCart>4}
                onClick={()=>addOneProduct()} style={{borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px', height:'100%'}}>
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              </div>
            </div>
          </Card.Section>

          <Card.Section className="cart-item-content cart-item-footer">
            <div className="cart-item-data cart-item-price">
              ₹{
                (priceAfterDiscount) ?
                (((deposit!==undefined?(deposit+priceAfterDiscount):priceAfterDiscount)*amountInCart)/100).toFixed(2):
                (((deposit!==undefined?(deposit+price):price)*amountInCart)/100).toFixed(2)
              }
              {/* {(deposit!==undefined) &&
                <>
                  {" ( +₹"+((deposit*amountInCart)/100).toFixed(2)+" )"}
                </>
              } */}
            </div> 
            <div className="cart-item-data remove-btn">
              <div className="hyperlink" style={{width:'100%', textAlign:'center', color:'#dc3545', fontSize:'10pt'}}
                onMouseDown={()=>deleteProduct()}
              >
                Remove
              </div>
            </div>
          </Card.Section>
        </div>
      </Card>
    )
  }