// import { app } from './service';
import { initializeApp } from "firebase/app";

import { getFirestore, collection, addDoc, getDoc, getDocs, doc, setDoc, connectFirestoreEmulator, where, query, Timestamp } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

const db = getFirestore();
// connectFirestoreEmulator(db, 'http://localhost', 8080);

export function getDocument(collectionName:string,docName:string) {

  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, collectionName, docName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      resolve(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      reject()
    }

  })
        
}

export function createDocument(collectionName:string,docName:string,data?:any) {

  return new Promise(async (resolve, reject) => {


    try {
      const colRef = collection(db, collectionName);
      
      setDoc(doc(colRef, docName), {
        ...data  
      }).then((newDoc)=>{
        resolve(newDoc)
      });
    } catch (e) {
      reject(e)
    }

  })
        
}

export function updateDocument(collectionName:string,docName:string,data:any) {

  return new Promise(async (resolve, reject) => {

    const docRef = doc(db, collectionName, docName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await setDoc(docRef, {
        ...data  
      }).then((updatedDoc)=>{
        console.log("UPDATED: ", updatedDoc);
        resolve(updatedDoc)
      });
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      reject()
    }

  })
        
}