import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartItemData } from '../../assets/utility/dataTypes';
import { createCODOrderAPI, createRazorpayOrderAPI } from '../api/orders';
import { AppThunk, RootState } from '../store';

export interface ProductState {
  fetching: boolean;
  cartBusy: boolean;
  fetchingDetails: boolean,
  cart: CartItemData[];
  productDetail: any | undefined;
}

const initialState: ProductState = {
  fetching: false,
  cartBusy: false,
  fetchingDetails: false,
  cart: [],
  productDetail: undefined
}

export const updateCart = (product:any, type:'addone'|'removeone'|'delete'|'create'): AppThunk => (
    dispatch,
    getState
  ) => {

        let currentCart = getCart(getState());
        if(type==='create'){
            dispatch(updateCartAction({index: 0, type:type, product:product }));
        }else{
            for (let i = 0; i < currentCart.length; i++) {

                if(product.name===currentCart[i].name){
                    dispatch(updateCartAction({index: i, type:type}));
                }
    
            }
        } 
};

export const createCODOrderAsync = createAsyncThunk(
    'product/createCODOrder',
    async ({orderData,creditsApplied}:{orderData:any,creditsApplied:boolean}) => {
      const response = await createCODOrderAPI({orderData,creditsApplied});
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const createRazorpayOrderAsync = createAsyncThunk(
    'product/createRazorpayOrder',
    async ({orderData,creditsApplied}:{orderData:any,creditsApplied:boolean}) => {
      const response = await createRazorpayOrderAPI({orderData,creditsApplied});
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        updateCartAction: (state, action: PayloadAction<{index:number, type:string, product?:any}>) => {
            const { index, type, product } = action.payload;
            switch (type) {
                case 'addone':
                    // if(state.cart[index].amountInCart<5){
                      state.cart[index].amountInCart = state.cart[index].amountInCart+1;
                    // }
                    break;

                case 'removeone':
                    if(state.cart[index].amountInCart>1){
                        state.cart[index].amountInCart = state.cart[index].amountInCart-1;
                    }else{
                        let currentCart = state.cart;
                        currentCart.splice(index,1);
                        state.cart = currentCart;
                    }
                    break;

                case 'delete':{
                    let currentCart = state.cart;
                    currentCart.splice(index,1);
                    state.cart = currentCart;
                    break;}
            
                case ('create'):{
                    let currentCart = state.cart;
                    if(product){
                        currentCart.push({amountInCart:1, ...product});
                    }
                    state.cart = currentCart;
                    break;}
                default:
                    break;
            }
        },
        emptyCart: (state) => {
            state.cart = [];
        },
      },
      extraReducers: (builder) => {
        builder
          .addCase(createCODOrderAsync.pending, (state) => {
            state.cartBusy = true;
          })
          .addCase(createCODOrderAsync.fulfilled, (state) => {
            state.cartBusy = false;
            state.cart = [];
          })
          .addCase(createCODOrderAsync.rejected, (state) => {
            state.cartBusy = false;
          })

          .addCase(createRazorpayOrderAsync.pending, (state) => {
            state.cartBusy = true;
          })
          .addCase(createRazorpayOrderAsync.fulfilled, (state) => {
            state.cartBusy = false;
          })
          .addCase(createRazorpayOrderAsync.rejected, (state) => {
            state.cartBusy = false;
          })
      }
})

export const { emptyCart, updateCartAction } = productsSlice.actions;

export const getCartBusy = (state: RootState) => state.products.cartBusy;
export const getCart = (state: RootState) => state.products.cart;

export default productsSlice.reducer;