import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

// LOGO
export const POWERPLANTFOODS_LOGO = require('./logos/powerplantfoods.svg');
export const POWERPLANTFOODS_TRADEMARK = require('./logos/powerplantfoods_tm.svg');

// BANNER
export const BANNER_TOFU = require('./images/tofu-banner.jpg');
export const BANNER_FRESH = require('./images/fresh-banner.jpg');

// PRODUCTS
export const SOYMILK = require('./products/soymilk.jpg');

// THIRDPARTY
export const GOOGLE_G = require('./third-party/google-g.svg');

//ICONS
export const faGlassBottle: IconDefinition = {// @ts-ignore
    prefix: 'fac',// @ts-ignore
    iconName: 'glassBottle',// @ts-ignore
    icon: [
        75, // width
        75, // height
      [], // ligatures
      '', // unicode
      [
        // "M22.5 32.7v29.6c-.1 1.5 1.2 2.8 2.7 2.9h21.6c1.5.1 2.8-1.2 2.9-2.7V32.7c0-2.2-.9-4.4-2.5-5.9l-2.9-2.9c-2.2-1.9-3.4-4.8-3.2-7.7v-2.3h-10V17c0 2.4-1 4.7-2.7 6.4L25 26.8c-1.6 1.6-2.5 3.7-2.5 5.9zm3.1 2.8c0-1.3.3-2.5.8-3.7.9-1.9 2.3-3.6 4.2-4.6 1-.6 2.3-.4 2.9.6l.1.1c.6 1 .3 2.4-.7 3-1.1.6-2 1.5-2.5 2.6-.3.7-.4 1.4-.4 2.1-.1 3-.1 10.8 0 20.6 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2-.1-9.9-.1-17.6 0-20.7z",
        "", "M27.8 58.4c1.2 0 2.2-1 2.2-2.2-.1-9.8-.1-17.6 0-20.6 0-.7.1-1.4.4-2.1.5-1.1 1.4-2 2.5-2.6 1-.6 1.3-2 .7-3-.6-1-1.9-1.4-2.9-.8l-.1.1c-1.9 1-3.3 2.7-4.2 4.6-.5 1.2-.8 2.4-.8 3.7-.1 3.1-.1 10.8 0 20.7 0 1.2 1 2.2 2.2 2.2z M26.3 2.6c-1.9.4-3.3 1.9-3.7 3.8-.1.8-.1 1.6-.1 2.4 0 1.8.9 3.5 2.5 4.4.2.1.3.4.3.6.1 1.1 0 2.1.1 3.1 0 1-.4 1.9-1.1 2.5L21 22.7c-2.7 2.7-4.2 6.4-4.2 10.3v29.2c-.1 4.7 3.6 8.6 8.3 8.7h21.5c4.7.2 8.6-3.5 8.8-8.2V33c.1-3.9-1.5-7.7-4.3-10.4l-3.3-3.3c-.7-.6-1-1.4-1-2.3.1-1 .1-2 0-3 0-.4.2-.8.6-1 1.4-.9 2.3-2.4 2.3-4V7.2c-.1-2.1-1.4-3.8-3.4-4.5l-.8-.3H26.7l-.4.2zM31.1 17v-3.1h10v2.3c-.2 2.9 1 5.8 3.2 7.7l2.9 2.9c1.6 1.5 2.5 3.7 2.5 5.9v29.6c.1 1.5-1.2 2.8-2.7 2.9H25.4c-1.5.1-2.8-1.2-2.9-2.7V32.7c0-2.2.9-4.3 2.5-5.9l3.4-3.4c1.7-1.7 2.7-4 2.7-6.4z",
      ] // svgPathData
    ]
}

export const faGlassJar: IconDefinition = {// @ts-ignore
    prefix: 'fac',// @ts-ignore
    iconName: 'glassJar',// @ts-ignore
    icon: [
        75, 75,
      [],
      '',
      [
        "", "M13.4 15.7c-1.9.4-3.3 2-3.6 3.9-.1.8-.2 1.5-.1 2.3 0 1.8.9 3.5 2.4 4.4.2.1.4.4.4.7v.3c.1.9-.3 1.9-1 2.5-1.2 1.1-2.3 2.2-3.4 3.4-2.7 2.7-4.3 6.4-4.2 10.2v18.8c-.1 4.7 3.6 8.6 8.3 8.7h47.3c4.7.2 8.6-3.5 8.8-8.2V43.4c0-3.9-1.5-7.6-4.3-10.3l-3.3-3.4c-.7-.6-1-1.4-1-2.3v-.2c0-.5.1-.8.5-1 1.4-.8 2.3-2.4 2.3-4 .1-.6.1-1.3 0-1.9-.1-2-1.4-3.8-3.3-4.5l-.8-.3H13.8l-.4.2zm4.8 11.7V27H54v-.4c-.3 2.9.9 5.8 3.2 7.7 1 .9 1.9 1.9 2.8 2.9 1.6 1.5 2.5 3.7 2.5 5.9.1 9.9.1 9.3 0 19.2.1 1.5-1 2.8-2.5 2.9H12.6c-1.5.1-2.8-1-2.9-2.5v-.4c-.1-9.9-.1-9.3 0-19.2 0-2.2.8-4.3 2.4-5.8 1.1-1.2 2.3-2.4 3.5-3.5 1.7-1.7 2.7-4 2.6-6.4z M14.9 58.4c1.2 0 2.2-1 2.2-2.2-.1-9.8-.1-7.1 0-10.2 0-.7.1-1.4.4-2 .5-1.1 1.4-2.1 2.5-2.7 1-.6 1.3-2 .7-3-.6-1-2-1.3-3-.7-1.8 1.1-3.3 2.7-4.2 4.6-.5 1.2-.7 2.5-.8 3.8 0 3-.1.3 0 10.2.1 1.2 1 2.1 2.2 2.2z",
        // "M9.7 43.1c-.1 9.9-.1 9.3 0 19.2-.1 1.5 1 2.8 2.5 2.9h47.4c1.5.1 2.8-1 2.9-2.5v-.4c.1-9.9.1-9.3 0-19.2 0-2.2-.9-4.4-2.5-5.9-.9-1-1.8-2-2.8-2.9-2.3-1.9-3.5-4.8-3.2-7.7v.4H18.2v.4c.1 2.4-.9 4.7-2.6 6.4-1.2 1.1-2.4 2.3-3.5 3.5-1.6 1.5-2.4 3.6-2.4 5.8zm3 2.9c.1-1.3.3-2.6.8-3.8.9-1.9 2.4-3.5 4.2-4.6 1-.6 2.4-.3 3 .7.6 1 .3 2.4-.7 3-1.1.6-2 1.6-2.5 2.7-.3.6-.4 1.3-.4 2-.1 3.1-.1.4 0 10.2 0 1.2-1 2.2-2.2 2.2-1.2-.1-2.1-1-2.2-2.2-.1-9.9 0-7.2 0-10.2z",
      ]
    ]
}

//ART
export const STARCAT = require('./artworks/starcat.svg');

//ABOUT IMG
export const SADCOWS = require('./images/sad-cows.jpg');
export const PLASTICWASTE = require('./images/plastic-waste.jpg');
export const MILKBOTTLES = require('./images/milk-bottles.jpg');
export const KITCHEN = require('./images/kitchen.jpg');
export const GLASSWARE = require('./images/glassware.jpg');
export const GREEN = require('./images/green.jpg');