/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/scss'
import 'swiper/scss/pagination'

import { faMultiply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button } from "@mantine/core";
import { SOYMILK } from "../../assets";
import { CartButton } from "../products/ProductTile";

import './menu.css';
import { useAppSelector } from '../../app/hooks';
import { getActiveOutlet } from '../../app/reducer/site.reducer';
import { ProductData } from '../../assets/utility/dataTypes';

interface ProductMenuProps{
  data: ProductData,
  amountInCart: number,
  addOneProduct: Function,
  removeOneProduct: Function,
  createProduct: Function,
  deleteProduct: Function,
  handleClose: Function
}

SwiperCore.use([Pagination]);

export default function ProductMenu({data, amountInCart, addOneProduct, removeOneProduct, createProduct, deleteProduct, handleClose}:ProductMenuProps):JSX.Element {
  
  const { id, name, quantity, unit, description, price, tags, ingredients, priceAfterDiscount, discountPercent, deposit, } = data;
  const activeOutlet = useAppSelector(getActiveOutlet);

    return (  
        <div className="side-menu-wrapper">
          {discountPercent && <div  className="product-offer-ribbon" style={{transform: 'scale(1.3)', right:'50px', top:'0px'}}>
            {discountPercent}%<br/>Off
          </div>}
          <div className="side-menu-header primary-text" onMouseDown={e=>{e.preventDefault();handleClose();}}>
            <FontAwesomeIcon icon={faMultiply} style={{float:'right'}}/>
            <span>{name}<br/><span style={{opacity:0.6, fontSize:'12pt', textTransform:'lowercase'}}>{quantity+unit}</span></span>
            <hr/>
          </div>
          <div className="side-menu-content secondary-text" style={{alignItems:'center'}}>
          <div className="product-menu-img">
            <Swiper
              spaceBetween={10} slidesPerView={'auto'}
              // pagination={{
              //   clickable: false,
              //   renderBullet: function (index, className) {
              //     return '<span class="product-menu-slider-bullet ' + className + '"></span>';
              //   },
              // }}
            >
              <SwiperSlide className="product-menu-img-slide">
                <img
                // src={SOYMILK}
                src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                alt={"An image of "+name+". Property of Power Plant Foods."}/>
              </SwiperSlide>
              {/* <SwiperSlide className="product-menu-img-slide">
                <img
                // src={SOYMILK}
                src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                alt={"An image of "+name+". Property of Power Plant Foods."}/>
              </SwiperSlide>
              <SwiperSlide className="product-menu-img-slide">
                <img
                // src={SOYMILK}
                src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                alt={"An image of "+name+". Property of Power Plant Foods."}/>
              </SwiperSlide>
              <SwiperSlide className="product-menu-img-slide">
                <img
                // src={SOYMILK}
                src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                alt={"An image of "+name+". Property of Power Plant Foods."}/>
              </SwiperSlide> */}
            </Swiper>
          </div>
          <div className="side-menu-list secondary-text menu-links product-menu-data">
            
            <div className="product-menu-price">
              <div>
                <div style={{display:'flex', paddingLeft:'5px', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                  {
                  (price)?
                    (priceAfterDiscount) ?
                      <div style={{fontSize: '20pt'}}>₹{((deposit!==undefined?(priceAfterDiscount+deposit):priceAfterDiscount)/100).toFixed(2)}&nbsp;</div>
                      :
                      <div style={{fontSize: '20pt'}}>₹{((deposit!==undefined?(price+deposit):price)/100).toFixed(2)}&nbsp;</div>
                    :
                    <div style={{fontSize: '20pt', opacity:0.5}}>Out fo stock</div>
                  }
                  {price!==0 && priceAfterDiscount!==undefined && <div style={{textDecoration:'line-through', opacity:0.5, fontSize:'9pt'}}>&nbsp;₹{(price/100).toFixed(2)}&nbsp;</div>}
                </div>

                {/* {(price!=0 && deposit!==undefined) &&
                  <div className="product-deposit">
                    {"( +₹"+(deposit/100).toFixed(2)+" deposit )"}
                  </div>
                } */}
              </div>
            </div>
              
            <div className="product-menu-cta" style={{width:'150px'}}>
              {price!==0?
                <CartButton
                  amountInCart={amountInCart}
                  addOneProduct={addOneProduct}
                  removeOneProduct={removeOneProduct}
                  createProduct={createProduct}
                  deleteProduct={deleteProduct}
                />
                :
                <Button disabled style={{width:'100%', padding:'0px', justifyContent:'center', backgroundColor:'#fff'}}
                >
                  {activeOutlet? "Out of Stock" : "Unavailable"}
                </Button>
              }
            </div>
          </div>
          <hr/>
          <div className="product-menu-desc product-tags-wrapper">
            {
              tags.map((tag,i)=>{
                return <Badge key={tag+i} color="green">{tag}</Badge>
              })
            }
          </div>
          <div className="product-menu-desc">
              {description}
          </div>
          <hr/>
          <div className="product-menu-desc">
              Ingredients:
              <div style={{fontWeight:500, padding:'10px'}}>
                {
                  ingredients.map((ingredient,i)=>{
                    if(i===(ingredients.length - 1)){
                      return <span>{ingredient}.</span>;
                    }else{
                      return <span>{ingredient}, </span>;
                    }
                  })
                }
              </div>
          </div>
          </div>
        </div>
    );
    
}