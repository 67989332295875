import axios, { AxiosResponse } from "axios";


const baseUrl = process.env.REACT_APP_CLOUD_FUNCTIONS_ENDPOINT;
const baseUrlWithFunctionName = baseUrl+"/orders";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = baseUrl+"";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

export function validateOrderAPI({cartItems,creditsApplied,referral}:{cartItems:{productId: string, qty: number}[],creditsApplied:number,referral?:{userId:string,referralNumber:string}}) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrlWithFunctionName}/validate-order`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {cartItems, creditsApplied, referral:referral?referral:undefined}
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function validateCouponAPI({userId,referralNumber}:{userId:string,referralNumber:string}) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrlWithFunctionName}/validate-coupon-code`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {userId, referralNumber}
        })
            .then((res) => {
                console.log(res)
                resolve({ response: res })
            })
            .catch((err) => {
                console.log(err.response.data.data.offerDesc);
                reject({ error: err.response.data.data.offerDesc })
            });
    });
}

export function createCODOrderAPI({orderData,creditsApplied}:{orderData:any,creditsApplied:boolean}) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrlWithFunctionName}/create-cod-order`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {orderData, creditsApplied}
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function createRazorpayOrderAPI({orderData,creditsApplied}:{orderData:any,creditsApplied:boolean}) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrlWithFunctionName}/create-razorpay-order`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {orderData, creditsApplied}
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}