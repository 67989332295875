import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/scss'
import 'swiper/scss/navigation'


import { useAppSelector } from "../../app/hooks";
import { getCart } from "../../app/reducer/products.reducer";
import ProductSkeleton from "./ProductSkeleton";

import ProductTile from "./ProductTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllProducts } from "../../app/reducer/site.reducer";
import { faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

SwiperCore.use([Autoplay]);

interface ProductsSectionProps{
    preview?:true;
    icon?: any;
    title: string;
    filterTag?: string;
    background?: string;
}

export default function AllProducts({preview,icon,filterTag,title,background}:ProductsSectionProps):JSX.Element {

    const products = useAppSelector(getAllProducts);
    const cart = useAppSelector(getCart);

    const swiperID = title.split(" ")[0].toLowerCase()+(new Date().getTime());

    return (  
        <div className="product-wrapper" style={{background:background?background:"#fff", marginBottom:'10px', borderRadius:'10px',position:'relative'}}>
            <div className="secondary-text" style={{textAlign: 'center', textTransform:'uppercase', padding: '15px'}}>
               {icon && <FontAwesomeIcon icon={icon}/>}&nbsp;{title}
            </div>
            <div className="all-products-container">
            {
                (products.length>0) ?
                    products.map((item)=>{

                        let amountInCart = 0;

                        for (let i = 0; i < cart.length; i++) {

                            if(item.name===cart[i].name){
                                amountInCart = cart[i].amountInCart;
                                break;
                            }
                            
                        }

                        if(filterTag!==undefined){
                            if(item.category.includes(filterTag)){
                            return(
                                <ProductTile data={item} amountInCart={amountInCart} preview={preview}/>
                            )}else{
                                return false;
                            }
                        }else{
                            return(
                                <ProductTile data={item} amountInCart={amountInCart} preview={preview}/>
                            )
                        }
                    })
                    :
                    <>
                    <SwiperSlide className="product-slide"><ProductSkeleton /></SwiperSlide>
                    <SwiperSlide className="product-slide"><ProductSkeleton /></SwiperSlide>
                    <SwiperSlide className="product-slide"><ProductSkeleton /></SwiperSlide>
                    <SwiperSlide className="product-slide"><ProductSkeleton /></SwiperSlide>
                    </>
                }
            </div>
            {/* <div className={"product-swiper-nav prev "+swiperID}><FontAwesomeIcon icon={faCircleChevronLeft}/></div>
            <div className={"product-swiper-nav next "+swiperID}><FontAwesomeIcon icon={faCircleChevronRight}/></div> */}
        </div>
    );
    
}