import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getCart } from "../../app/reducer/products.reducer";
import CartItem from "./CartItem";
import CartItemCheckout from "./CartItemCheckout";

interface CartProps{
  checkoutMode?: boolean
}
export default function Cart({checkoutMode}:CartProps):JSX.Element {
    const [totalamt, setTotalAmt] = useState<number>(0);
    const [refundamt, setRefundAmt] = useState<number>(0);

    const navigate = useNavigate();
    
    const cart = useAppSelector(getCart);
  
    useEffect(() => {
  
      let totalTemp = 0;
      let refundTemp = 0;

      cart.forEach((cartItem)=>{
        const itemPrice = (
          (cartItem.priceAfterDiscount)?
            cartItem.priceAfterDiscount
          : cartItem.price) * cartItem.amountInCart;

        totalTemp = totalTemp + itemPrice;

        if(cartItem.deposit){
          refundTemp = refundTemp + (cartItem.deposit * cartItem.amountInCart);
        }
      })
  
      setTotalAmt(totalTemp);
      setRefundAmt(refundTemp);
  
    }, [cart]);

    return ( 
      <>
        {!checkoutMode && <div className="secondary-text">
          Cart Items:&nbsp;{(cart.length>0) && <Badge bg="custom" style={{backgroundColor:'#12b886'}}>{cart.length}</Badge>}
        </div>}
        <div className="secondary-text cart-well" style={{flex:1}}>
          { 
            (cart.length>0)?
            cart.map((cartItem)=>{
              return(
                checkoutMode?
                <CartItemCheckout data={cartItem} key={cartItem.name}/>
                :
                <CartItem data={cartItem} key={cartItem.name}/>
              )
            })
            :
            <div style={{minHeight:'300px', width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', opacity:1}}>
              <FontAwesomeIcon icon={faCartPlus} style={{fontSize:'100px', marginBottom:'10px', opacity:0.3}}/>
              <div style={{opacity:0.3}}>Looks like your cart is empty</div>
              {checkoutMode && <div style={{textDecoration:'underline', cursor:'pointer', opacity:0.6, transform:'scale(0.8)'}} onMouseDown={()=>navigate('/store', { replace: true })}>Continue shopping</div>}
            </div>
          }
        </div>
      </>
    );
      
  }