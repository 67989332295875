/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faArrowLeft, faArrowRight, faCheckCircle, faFileAlt, faHandsHelping, faList, faQuestion, faSearch, faSmile, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModals } from "@mantine/modals";
import { Card, Button, Avatar } from "@mantine/core";
import { useNotifications } from '@mantine/notifications';
import { useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { signoutAction, getAuthFetching } from "../../app/reducer/auth.reducer";
import SignInPopUp from "../modals/LoginModal";

import './menu.css';
import UserCard from "../user/UserCard";
import { setDeliveryDetails, setDeliveryName, setDeliveryPhoneNumber, setShowMenu } from "../../app/reducer/site.reducer";
import { AppDispatch } from "../../app/store";

interface MainMenuProps{
  isAuth: boolean,
  user?: any
}

export default function MainMenu({isAuth, user}:MainMenuProps):JSX.Element {
  
  const modals = useModals();
  const dispatch = useAppDispatch();
  const notifications = useNotifications();
  const navigate = useNavigate();

  const openLoginModal = () =>{
    modals.openContextModal('login', {
    title: <div className="modal-title secondary-text">SIGN IN</div>,
    innerProps: {
      callback: ()=>{return}
      }
    });
    dispatch(setShowMenu(false));
  }

    return (  
        <div className="side-menu-wrapper">
          <div className="side-menu-header primary-text" onMouseDown={e=>{e.preventDefault();dispatch(setShowMenu(false));}}>
            <FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;
              <span>MAIN MENU</span>
          </div>
          <hr/>
          <div className="side-menu-content secondary-text" style={{alignItems:'center'}}>
          {(isAuth && user) ?
            <UserCard user={user} profileBtnOnClick={()=>{navigate('/my-profile');dispatch(setShowMenu(false));}} ordersBtnOnClick={()=>{navigate('/my-orders');dispatch(setShowMenu(false));}} />
            :
            <Button style={{width:'100%'}}
            // onClick={()=>dispatch(signupWithGoogleAction())}>
            onClick={()=>openLoginModal()}>
              SIGN IN
            </Button>
          }
          <hr/>
          <div className="side-menu-list secondary-text menu-links">
              {/* <a >
                <FontAwesomeIcon icon={faHandsHelping}/>&nbsp;&nbsp;Customer Support
              </a> */}
              {/* <a >
                <FontAwesomeIcon icon={faSearch}/>&nbsp;&nbsp;Find Store
              </a> */}
              {/* <a >
                <FontAwesomeIcon icon={faQuestion}/>&nbsp;&nbsp;How it works
              </a> */}
              <a style={{cursor:'pointer'}} onMouseDown={(e)=>{e.preventDefault();navigate('/about-Us');dispatch(setShowMenu(false));}}>
                <FontAwesomeIcon icon={faFileAlt}/>&nbsp;&nbsp;About Us
              </a>
              <a style={{cursor:'pointer'}} onMouseDown={(e)=>{e.preventDefault();navigate('/privacy-policy');dispatch(setShowMenu(false));}}>
                <FontAwesomeIcon icon={faFileAlt}/>&nbsp;&nbsp;Privacy Policy
              </a>
              <a style={{cursor:'pointer'}} onMouseDown={(e)=>{e.preventDefault();navigate('/terms-and-conditions');dispatch(setShowMenu(false));}}>
                <FontAwesomeIcon icon={faFileAlt}/>&nbsp;&nbsp;Terms & Conditions
              </a>
          </div>
          {isAuth &&
          <div className="side-menu-cta secondary-text" style={{alignItems:'center'}}>
            <Button style={{width:'100%'}} color="orange"
            onClick={async()=>{
              dispatch(signoutAction());
              dispatch(setDeliveryName(''));
              dispatch(setDeliveryPhoneNumber(''));
              dispatch(setDeliveryDetails({
                house: '',
                address: '',
                lat: 0,
                lng: 0,
                city: '',
                pincode: ''
              }));
              dispatch(setShowMenu(false));
              notifications.showNotification({
                color:"green",
                icon: <FontAwesomeIcon icon={faCheckCircle}/>,
                title: 'Logged Out',
                message: 'See you again!',
                autoClose: true
              })
              }}>
              LOG OUT
            </Button>
          </div>
          }
          </div>
        </div>
    );
    
}