import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OutletData, ProductData } from '../../assets/utility/dataTypes';
import { validateOrderAPI, validateCouponAPI } from '../api/orders';
import { getProductsFromOutletPincodeAPI, getProductsFromOutletIdAPI, getAllProductsAPI } from '../api/products';
import { RootState } from '../store';

export interface siteState {
    fetching: boolean;
    askedPincode: boolean;
    catalogue: ProductData[];
    showCart: boolean;
    showMenu: boolean;
    activeCity: any;
    activeOutlet?: OutletData; 
    deliveryName: string;
    deliveryPhoneNumber: string;
    deliveryDetails: {
        house: string;
        address: string;
        lat: number;
        lng: number;
        city: string;
        pincode: string;
    },
    usePPFCredits: boolean;
    offerCode: string | undefined;
    offer: {
        offerDiscount: number | undefined,
        offerText: number | undefined,
        offerDesc: string | undefined
    };
    orderDetails: {
        itemsCost?: number,
        refundableCost?: number,
        subTotal?: number,
        deliveryFee?: number,
        creditsApplied?: number,
        discount?: number,
        grandTotal?: number
    }
}

const initialState: siteState = {
    fetching: false,
    askedPincode: false,
    catalogue: [],
    showCart: false,
    showMenu: false,
    activeCity: {
        "cityString": "mumbai",
        "cityText": "Mumbai",
        "sw":{"lat":18.818417,"lng":72.65828},
        "ne":{"lat":19.315331,"lng":73.03456}
    },
    activeOutlet: undefined,
    deliveryName: '',
    deliveryPhoneNumber: '',
    deliveryDetails: {
        house: '',
        address: '',
        lat: 0,
        lng: 0,
        city: '',
        pincode: ''
    },
    usePPFCredits: false,
    offerCode: undefined,
    offer: {
        offerDiscount: undefined,
        offerText: undefined,
        offerDesc: undefined
    },
    orderDetails: {}
}

export const getProductsFromOutletPincodeAsync = createAsyncThunk(
    'site/getProductsFromOutletPincode',
    async (pincode:string) => {
      const response = await getProductsFromOutletPincodeAPI(pincode);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const getProductsFromOutletIdAsync = createAsyncThunk(
    'site/getProductsFromOutletId',
    async (outletId:string) => {
      const response = await getProductsFromOutletIdAPI(outletId);
      return response;
    }
);

export const getAllProductsAsync = createAsyncThunk(
    'site/getAllProducts',
    async () => {
      const response = await getAllProductsAPI();
      return response;
    }
);

export const validateOrderAsync = createAsyncThunk(
    'site/validateOrder',
    async ({cartItems,creditsApplied,referral}:{cartItems:{productId: string, qty: number}[],creditsApplied:number,referral?:{userId:string,referralNumber:string}}) => {
      const response = await validateOrderAPI({cartItems,creditsApplied,referral});
      return response;
    }
);

export const validateCouponAsync = createAsyncThunk(
    'site/validateCoupon',
    async ({userId,referralNumber}:{userId:string,referralNumber:string}) => {
      const response = await validateCouponAPI({userId,referralNumber});
      return response;
    }
);

export const siteSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAskedPincode: (state, action) => {
            state.askedPincode = action.payload;
        },
        setShowCart: (state, action) => {
            state.showCart = action.payload;
        },
        setShowMenu: (state, action) => {
            state.showMenu = action.payload;
        },
        setActiveCity: (state, action) => {
            state.activeCity = action.payload;
        },
        setActiveOutlet: (state, action) => {
            state.activeOutlet = action.payload;
        },
        setDeliveryName: (state, action) => {
            state.deliveryName = action.payload;
        },
        setDeliveryPhoneNumber: (state, action) => {
            state.deliveryPhoneNumber = action.payload;
        },
        setDeliveryDetails: (state, action) => {
            const { house, address, lat, lng, city, pincode} = action.payload
            state.deliveryDetails.house = house;
            state.deliveryDetails.address = address;
            state.deliveryDetails.lat = lat;
            state.deliveryDetails.lng = lng;
            state.deliveryDetails.city = city;
            state.deliveryDetails.pincode = pincode;
        },
        setUsePPFCredits: (state, action) => {
            state.usePPFCredits = action.payload;
        },
        setOfferCode: (state, action) => {
            state.offerCode = action.payload;
        },
        emptyOffer: (state) => {
            state.offerCode = undefined;
            state.offer = {
                offerDiscount: undefined,
                offerText: undefined,
                offerDesc: undefined
            };
        },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getProductsFromOutletPincodeAsync.pending, (state) => {
            state.fetching = true;
        })
        .addCase(getProductsFromOutletPincodeAsync.fulfilled, (state, action) => {
          state.activeOutlet = action.payload.response.data.data.outletData;
          state.catalogue = action.payload.response.data.data.productsData;
          state.fetching = false;
        })
        .addCase(getProductsFromOutletPincodeAsync.rejected, (state) => {
            state.fetching = false;
        })

        .addCase(getProductsFromOutletIdAsync.pending, (state) => {
            state.fetching = true;
        })
        .addCase(getProductsFromOutletIdAsync.fulfilled, (state, action) => {
            state.activeOutlet = action.payload.response.data.data.outletData;
            state.catalogue = action.payload.response.data.data.productsData;
          state.fetching = false;
        })
        .addCase(getProductsFromOutletIdAsync.rejected, (state) => {
            state.fetching = false;
        })

        .addCase(getAllProductsAsync.pending, (state) => {
            state.fetching = true;
        })
        .addCase(getAllProductsAsync.fulfilled, (state, action) => {
            let tempCatalogue = action.payload.response.data.data;
            
            state.catalogue = tempCatalogue;
            state.fetching = false;
        })
        .addCase(getAllProductsAsync.rejected, (state) => {
            state.fetching = false;
        })

        .addCase(validateOrderAsync.pending, (state) => {
        })
        .addCase(validateOrderAsync.fulfilled, (state, action) => {
            state.orderDetails = action.payload.response.data.data;
        })
        .addCase(validateOrderAsync.rejected, (state) => {

        })

        .addCase(validateCouponAsync.pending, (state) => {
            state.offerCode = undefined;
            state.offer = {
                offerDiscount: undefined,
                offerText: undefined,
                offerDesc: undefined
            };
        })
        .addCase(validateCouponAsync.fulfilled, (state, action) => {
            state.offer = action.payload.response.data.data;
        })
        .addCase(validateCouponAsync.rejected, (state) => {
            
        })

    }
})

export const { setAskedPincode, setShowCart, setShowMenu, setActiveCity, setActiveOutlet, setDeliveryName, setDeliveryPhoneNumber, setDeliveryDetails, setUsePPFCredits, setOfferCode, emptyOffer } = siteSlice.actions;

export const getAskedPincode = (state: RootState) => state.site.askedPincode;

export const getAllProducts = (state: RootState) => state.site.catalogue;

export const getShowCart = (state: RootState) => state.site.showCart;
export const getShowMenu = (state: RootState) => state.site.showMenu;

export const getActiveCity = (state: RootState) => state.site.activeCity;
export const getActiveOutlet = (state: RootState) => state.site.activeOutlet;

export const getUsePPFCredits = (state: RootState) => state.site.usePPFCredits;

export const getDeliveryName = (state: RootState) => state.site.deliveryName;
export const getDeliveryPhoneNumber = (state: RootState) => state.site.deliveryPhoneNumber;
export const getDeliveryDetails = (state: RootState) => state.site.deliveryDetails;

export const getOfferCode = (state: RootState) => state.site.offerCode;
export const getOffer = (state: RootState) => state.site.offer;

export const getOrderDetails = (state: RootState) => state.site.orderDetails;

export default siteSlice.reducer;