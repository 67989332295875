import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/scss'
import 'swiper/scss/pagination'

import Card from 'react-bootstrap/esm/Card';
import { BANNER_FRESH, BANNER_TOFU } from 'assets';
import { Navigate, useNavigate } from 'react-router-dom';

const homeBanners = [
    "red",
    "blue",
    "purple",
    "green",
    "yellow",
    "black",
]

SwiperCore.use([Pagination, Autoplay]);

export default function HomeSwiper():JSX.Element {
  const navigate = useNavigate();
    
  return(
    <div>
        <Swiper
        autoplay={{delay: 8000}}
        spaceBetween={5} slidesPerView={1}
        pagination={{
          clickable: false,
          renderBullet: function (index, className) {
            return '<span class="product-menu-slider-bullet ' + className + '"></span>';
          },
        }}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        >
          {/* <SwiperSlide style={{paddingBottom:'35px'}}>
            <Card style={{height:'150px', padding:'20px', color:'white', textAlign:'center', fontSize:'18pt', fontWeight:900, textShadow: '2px 2px rgb(63 63 63 / 66%) 0px 1px 6px', background: 'linear-gradient(27deg, rgba(244,35,83,1) 0%, rgba(244,35,146,1) 50%, rgba(244,35,202,1) 100%)', display:'flex', justifyContent:'center', alignItems:'center'}}>
                Launch offer! Get a jar of Peanut Butter free on your first order!
            </Card>
          </SwiperSlide> */}
          <SwiperSlide style={{paddingBottom:'35px', width:'100%'}}>
            <img src={BANNER_TOFU} alt="tofu banner"style={{width:'100%', borderRadius:"10px", cursor:'pointer'}}
              onClick={()=>navigate("/store/firm-tofu")}
            />
          </SwiperSlide>
          <SwiperSlide style={{paddingBottom:'35px', width:'100%'}}>
            <img src={BANNER_FRESH} alt="tofu banner"style={{width:'100%', borderRadius:"10px"}}/>
          </SwiperSlide>
        </Swiper>
    </div>
  )

}