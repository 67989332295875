
import {useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import { Container, Col, Row } from 'react-bootstrap';
import { faArrowLeft, faCartShopping, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Affix, Button, Badge, Transition } from '@mantine/core';
import { useViewportSize } from "@mantine/hooks";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss'
import 'swiper/scss/navigation'

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getActiveOutlet, getAllProductsAsync, getProductsFromOutletIdAsync, setShowCart } from '../app/reducer/site.reducer';

import { getCart, updateCart } from '../app/reducer/products.reducer';
import { unwrapResult } from '@reduxjs/toolkit';
import { CartButton } from 'components/products/ProductTile';
import { getSingleProductAPI } from 'app/api/products';
import { useNavigate, useParams } from 'react-router-dom';
import ProductsSection from 'components/products';
import AllProducts from 'components/products/AllProducts';
import { POWERPLANTFOODS_LOGO } from 'assets';
import { getRandomInt } from 'assets/utility/util';

const quotesArr = [
  "No artifical flavouring and preservatives beyond this point.",
  "No animals were harmed while making this webapp.",
  "Loading Plant-based health...",
  "Better for you. Better for our planet.",
  "Petting cats..."
]

function FloatingCartButton():JSX.Element {
  const [totalamt, setTotalAmt] = useState<number>(0);
  const [refundamt, setRefundAmt] = useState<number>(0);

  const cart = useAppSelector(getCart)
  const dispatch = useAppDispatch();

  let totalItems = 0;

  cart.forEach((item)=>{
    totalItems = totalItems + item.amountInCart
  });

  useEffect(() => {
  
    let totalTemp = 0;
    let refundTemp = 0;

    cart.forEach((cartItem)=>{
      
      const itemPrice = (
        cartItem.priceAfterDiscount?
        cartItem.priceAfterDiscount
        : cartItem.price) * cartItem.amountInCart;

      totalTemp = totalTemp + itemPrice;

      if(cartItem.deposit){
        refundTemp = refundTemp + (cartItem.deposit * cartItem.amountInCart);
      }

    })

    setTotalAmt(totalTemp);
    setRefundAmt(refundTemp);

  }, [cart]);

  return(
    <div className="primary-text mobile-only"
    style={{
      display: cart.length >0?"block":"none",
      alignItems:'center',
      position: 'fixed',
      bottom: 0, left: 0, right: 0,
      zIndex: 10,
      boxShadow: 'rgb(82 185 135 / 64%) 0px 4px 20px 11px',
      WebkitBoxShadow: 'rgb(82 185 135 / 64%) 0px 4px 20px 11px'
    }}>
      <Button
        color="teal"
        radius="xs" fullWidth size="lg"
        style={{padding: '5px 15px'}}
        onClick={()=>dispatch(setShowCart(true))}
        >
          <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div style={{fontSize:'12pt'}}>
                {totalItems}&nbsp;{totalItems>1?"Items":"Item"} | ₹{((totalamt+refundamt)/100).toFixed(2)}
              </div>
              <div style={{fontSize:'10pt', opacity:0.8, marginTop:'5px'}}>
                {((totalamt+refundamt)>14900)?
                "Eligible for Free Delivery!"
                :
                "Order above ₹149 for free delivery"
                }
              </div>
            </div>
            <div style={{flex:1, width:'550px'}}>
              
            </div>
            <div style={{fontWeight:600}}>
            <FontAwesomeIcon icon={faCartShopping}/>&nbsp; VIEW CART
            </div>
          </div>
      </Button>
    </div>
  )
}

function FloatingCartButtonAffix():JSX.Element {

  const cart = useAppSelector(getCart)
  const dispatch = useAppDispatch();

  return(
    <Affix position={{ bottom: 20, right: 20 }} zIndex={10}>
      <Transition transition="slide-up" mounted={cart.length > 0}>
        {(transitionStyles) => (
          <Button className="pc-only"
            color="teal"
            leftIcon={<FontAwesomeIcon icon={faCartShopping} />}
            style={transitionStyles}
            onClick={()=>dispatch(setShowCart(true))}
          >
            VIEW CART
          </Button>
        )}
      </Transition>
    </Affix>
  )
}

export default function Product():JSX.Element {
  const { productQuery } = useParams();
  if(productQuery){
    ReactGA.send({ hitType: "pageview", page: "/store/"+productQuery });
    ReactPixel.pageView();
  }
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeOutlet = useAppSelector(getActiveOutlet);
  const cart = useAppSelector(getCart);
  

  const [quoteIndex, setQuoteIndex] = useState<number>(getRandomInt(0,5));
  const [ready, setReady] = useState<boolean>(false);
  const [productData, setProductData] = useState<any>(undefined);
  const { height, width } = useViewportSize();
  // const askedPincode = useAppSelector(getAskedPincode);

  const addOneProduct = () => {
    dispatch(updateCart(productData,'addone'))
  }

  const removeOneProduct = () => {
    dispatch(updateCart(productData,'removeone'))
  }

  const createProduct = () => {
    ReactGA.event({ category: "Products", action: "Added to cart", label: productData.id});
    ReactPixel.track("Added to cart", {product: productData.id});
    dispatch(updateCart(productData,'create'))
  }

  const deleteProduct = () => {
    dispatch(updateCart(productData,'delete'))
  }

  let amountInCart = 0;

  for (let i = 0; i < cart.length; i++) {

      if(productData.name===cart[i].name){
          amountInCart = cart[i].amountInCart;
          break;
      }
      
  }
  // const modals = useModals();

  const initiateStore = async () => {

    const localOutletId = localStorage.getItem("ppf_oultet_id")
    if(localOutletId){

      await dispatch(getProductsFromOutletIdAsync(localOutletId)).then(unwrapResult)
      .catch(()=>{
        dispatch(getAllProductsAsync());
      });

    }else{

      // modals.openContextModal('location', {
      //   title: <div className="modal-title secondary-text">ENTER YOUR PINCODE:</div>,
      //   innerProps: {
      //     callback: ()=>{
      //       return;
      //     },
      //   },
      //   closeOnClickOutside: false,
      //   withCloseButton: false,
      //   closeOnEscape: false
      // });
    }
    // dispatch(setAskedPincode(true));

    dispatch(getAllProductsAsync());
    
  }

  useEffect(() => {
    if(productQuery){
      getSingleProductAPI(productQuery).then((res)=>{
        setReady(true);
        setProductData(res.response.data.data);
        if(activeOutlet && activeOutlet.id){
          dispatch(getProductsFromOutletIdAsync(activeOutlet.id));
        }else{
          initiateStore();
        }
      }).catch(()=>{
        navigate('/store')
      })
    }


  }, [])
  
  return (
    <div className="page-body">
      <section className="section">
          {ready && productData ?<Container>
              {productData.discountPercent && <div  className="product-offer-ribbon" style={{transform: 'scale(1.3)', right:'50px', top:'0px'}}>
              {productData.discountPercent}%<br/>Off
            </div>}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{productData.name}</title>
                <link rel="canonical" href={"https://powerplantfoods.in/store/"+productQuery} />
            </Helmet>
            {/* <div className="side-menu-header primary-text">
              <span>{productData.name}<br/><span style={{opacity:0.6, fontSize:'12pt', textTransform:'lowercase'}}>{productData.quantity+productData.unit}</span></span>
             
            </div> */}
              <div className="primary-text" style={{paddingBottom:'20px'}}>
                  <span onMouseDown={e=>{e.preventDefault();navigate('/store')}} style={{textDecoration:'underline', cursor:'pointer'}}><FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Store&nbsp;</span> /&nbsp;{productData.name}
              </div>
              <hr/>
            <Row>
              <Col sm={12} lg={6}>
                <div className="product-menu-img">
                  <Swiper
                    spaceBetween={10} slidesPerView={'auto'}
                    // pagination={{
                    //   clickable: false,
                    //   renderBullet: function (index, className) {
                    //     return '<span class="product-menu-slider-bullet ' + className + '"></span>';
                    //   },
                    // }}
                  >
                    <SwiperSlide className="product-menu-img-slide">
                      <img
                      // src={SOYMILK}
                      src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${productData.id}_thumbnail.png?alt=media`}
                      alt={"An image of "+productData.name+". Property of Power Plant Foods."}/>
                    </SwiperSlide>
                    {/* <SwiperSlide className="product-menu-img-slide">
                      <img
                      // src={SOYMILK}
                      src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                      alt={"An image of "+name+". Property of Power Plant Foods."}/>
                    </SwiperSlide>
                    <SwiperSlide className="product-menu-img-slide">
                      <img
                      // src={SOYMILK}
                      src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                      alt={"An image of "+name+". Property of Power Plant Foods."}/>
                    </SwiperSlide>
                    <SwiperSlide className="product-menu-img-slide">
                      <img
                      // src={SOYMILK}
                      src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
                      alt={"An image of "+name+". Property of Power Plant Foods."}/>
                    </SwiperSlide> */}
                  </Swiper>
                </div>
              </Col>

              <Col sm={12} lg={6}>
                <div className="side-menu-list secondary-text menu-links product-menu-data">
                  
                  <div className="product-menu-price">
                    <div>
                      <div style={{display:'flex', paddingLeft:'5px', flexDirection:'row', justifyContent:'flex-start', alignItems:'center'}}>
                        {
                        (productData.price)?
                          (productData.priceAfterDiscount) ?
                            <div style={{fontSize: '20pt'}}>₹{((productData.deposit!==undefined?(productData.priceAfterDiscount+productData.deposit):productData.priceAfterDiscount)/100).toFixed(2)}&nbsp;</div>
                            :
                            <div style={{fontSize: '20pt'}}>₹{((productData.deposit!==undefined?(productData.price+productData.deposit):productData.price)/100).toFixed(2)}&nbsp;</div>
                          :
                          <div style={{fontSize: '20pt', opacity:0.5}}>Coming Soon</div>
                        }
                        {productData.price!==0 && productData.priceAfterDiscount!==undefined && <div style={{textDecoration:'line-through', opacity:0.5, fontSize:'9pt'}}>&nbsp;₹{(productData.price/100).toFixed(2)}&nbsp;</div>}
                      </div>

                      {/* {(price!=0 && deposit!==undefined) &&
                        <div className="product-deposit">
                          {"( +₹"+(deposit/100).toFixed(2)+" deposit )"}
                        </div>
                      } */}
                    </div>
                  </div>
                    
                  <div className="product-menu-cta" style={{width:'150px'}}>
                    {productData.price!==0?
                      <CartButton
                        amountInCart={amountInCart}
                        addOneProduct={addOneProduct}
                        removeOneProduct={removeOneProduct}
                        createProduct={createProduct}
                        deleteProduct={deleteProduct}
                      />
                      :
                      <Button disabled style={{width:'100%', padding:'0px', justifyContent:'center', backgroundColor:'#fff'}}
                      >
                        {activeOutlet? "Out of Stock" : "Unavailable"}
                      </Button>
                    }
                  </div>
                </div>
                <hr/>
                <div className="product-menu-desc product-tags-wrapper">
                  {
                    productData.tags.map((tag,i)=>{
                      return <Badge key={tag+i} color="green">{tag}</Badge>
                    })
                  }
                </div>
                <div className="product-menu-desc">
                    {productData.description}
                </div>
                <hr/>
                <div className="product-menu-desc">
                    Ingredients:
                    <div style={{fontWeight:500, padding:'10px'}}>
                      {
                        productData.ingredients.map((ingredient,i)=>{
                          if(i===(productData.ingredients.length - 1)){
                            return <span>{ingredient}.</span>;
                          }else{
                            return <span>{ingredient}, </span>;
                          }
                        })
                      }
                    </div>
                </div>
                
              </Col>
              <Col sm={12} style={{padding: 0}}>
                {width>991?
                  <AllProducts icon={faLeaf} title="You may also like" background="white"/>
                  :
                  <ProductsSection icon={faLeaf} title="You may also like" background="white"/>
                }
              </Col>
            </Row>

          </Container>
          :
          <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>     
              <div className="spinner">
              </div>
              <img
                className="spinner-badge"
                style={{marginLeft:'-30px', marginTop:'-30px'}}
                src={POWERPLANTFOODS_LOGO}
                height="60px"
                alt="PowerPlant Foods logo"
              />
              <div style={{fontWeight:900, marginTop:'140px', padding:'100px', textAlign:'center'}}>
                {quotesArr[quoteIndex]}
              </div>
          </div>
          }
          <FloatingCartButton />
          <FloatingCartButtonAffix/>
      </section>
    </div>
  );

}

// const mapStateToProps = ( state: IRootState, ownProps = {} ) => ({
//   auth: state.auth
// });

// export default connect(mapStateToProps)(Home)
