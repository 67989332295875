import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import siteReducer from './reducer/site.reducer';
import authReducer from './reducer/auth.reducer';
import ordersReducer from './reducer/orders.reducer';
import productsReducer from './reducer/products.reducer';
import affiliateReducer from './reducer/affiliate.reducer';

export const store = configureStore({
  reducer: {
    site: siteReducer,
    auth: authReducer,
    orders: ordersReducer,
    products: productsReducer,
    affiliate: affiliateReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
