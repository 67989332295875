import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { useNotifications } from '@mantine/notifications';
import ReactGA from 'react-ga4';


import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAuth, getUser } from "../../app/reducer/auth.reducer";
import { getCartBusy, getCart } from "../../app/reducer/products.reducer";

import './menu.css';
import Cart from "../cart";
import { setShowCart } from "../../app/reducer/site.reducer";
import { useNavigate } from "react-router-dom";

export default function CartMenu():JSX.Element {
  const [totalamt, setTotalAmt] = useState<number>(0);
  const [refundamt, setRefundAmt] = useState<number>(0);
  
  const modals = useModals();
  const dispatch = useAppDispatch()
  const notifications = useNotifications();
  const navigate = useNavigate();
  
  const isAuth = useAppSelector(getAuth)
  const user = useAppSelector(getUser);
  const busy = useAppSelector(getCartBusy);
  const cart = useAppSelector(getCart);

  useEffect(() => {

    let totalTemp = 0;
    let refundTemp = 0;

    cart.forEach((cartItem)=>{
      const itemPrice = (
        cartItem.priceAfterDiscount?
        cartItem.priceAfterDiscount
        : cartItem.price) * cartItem.amountInCart;

      totalTemp = totalTemp + itemPrice;

      if(cartItem.deposit){
        refundTemp = refundTemp + (cartItem.deposit * cartItem.amountInCart);
      }
    })

    setTotalAmt(totalTemp);
    setRefundAmt(refundTemp);

  }, [cart]);

  const checkout = async () => {

    // if(!isAuth){
    //     modals.openContextModal('login', {
    //       title: <div className="modal-title secondary-text">SIGN IN</div>,
    //       innerProps: {
    //         callback: ()=>{
    //           navigate('/checkout')
    //           dispatch(setShowCart(false));
    //         }
    //       }
    //     });
    //     dispatch(setShowCart(false));
    //     return;
    // }

    navigate('/checkout')
    dispatch(setShowCart(false));
    
  }

  return ( 
    <div className="side-menu-wrapper" style={{paddingBottom:'150px'}}>
      <div className="side-menu-header primary-text" onMouseDown={e=>{e.preventDefault();dispatch(setShowCart(false));}}>
        <FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;
        <span>MY CART</span>
      </div>
      <hr/>
      <div className="side-menu-content">
        <Cart />
      </div>
      {(cart.length > 0) && <Card shadow="sm" className="secondary-text side-menu-cta" style={{padding:'10px'}}>
        <div style={{marginTop: '5px', marginBottom: '5px', display:'flex', justifyContent:'space-between'}}><div>Total:</div>₹{((totalamt+refundamt)/100).toFixed(2)}</div>
        {/* <div style={{fontSize: '10pt', display:'flex', justifyContent:'space-between'}}><div>Item Cost:&nbsp;<span style={{opacity:0.5, fontSize: '8pt'}}>(Inclusive of GST)</span></div>₹{((totalamt)/100).toFixed(2)}</div> */}
        <div style={{ marginBottom: '5px', fontSize: '10pt', display:'flex', justifyContent:'space-between'}}><div>Eligible refund:&nbsp;<span style={{opacity:0.5, fontSize: '8pt'}}>(After you return glassware)</span></div>₹{(refundamt/100).toFixed(2)}</div>
        {/* <div style={{marginTop: '5px', marginBottom: '5px', display:'flex', justifyContent:'space-between'}}><div>Sub Total:</div>₹{((totalamt+refundamt)/100).toFixed(2)}</div> */}
        <div>
          <Button disabled={busy}
            color="teal" style={{width:'100%'}}
            loading={busy}
            onClick={()=>{
              ReactGA.event({ category: "Products", action: "Went to checkout"});
              checkout();
              }}>
            CHECKOUT
          </Button>
        </div>
      </Card>}
    </div>
  );
    
}