import { Loader } from "@googlemaps/js-api-loader"
import { TextInput } from "@mantine/core";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getActiveCity } from "../../app/reducer/site.reducer";
// import { getCityBounds } from "../../app/reducer/order.reducer";

import './placesAutocomplete.css';

let APIKEY : string;
APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

declare global {
  interface Window {
    gmapsLoaded: boolean;
    loader: Loader;
  }
}

if (!window.gmapsLoaded) {
  console.log('GMAP LOADED');
  const loader = new Loader({
    apiKey: APIKEY,
    version: "weekly",
    libraries: ["places"]
  });
  window.loader = loader;
  window.gmapsLoaded = true;
}
  
interface PlacesAutocompleteProps{
    id: string,
    placeholder: string,
    label: string,
    description?: string,
    error?: any,
    setData: Function,
    onSelect?: Function,
    onMouseDown?: Function,
    className?: string,
    disabled?: boolean,
    readonly?: boolean,
    value?: string
}

export default function PlacesAutocomplete({id, placeholder, label, description, error, disabled, readonly, setData, onSelect, onMouseDown, className, value}:PlacesAutocompleteProps):JSX.Element {
    
    const dispatch = useAppDispatch()
    const cityBounds = useAppSelector(getActiveCity);

    const clearData = () =>{

        var placeData:any = {
            address: '',
            pincode: '',
            lat: 0,
            lng: 0
        };
        // dispatch(setData(placeData));
        setData(placeData);

    }

    useEffect(() => {
        if(cityBounds===undefined){
            return;
        };

        let autocomplete: google.maps.places.Autocomplete;
        const input = document.getElementById(id) as HTMLInputElement;

        
        if(value){
            input.value = value;
        }else{
            input.value = '';
        }
        
        if(readonly){
            return;
        }
        
        window.loader
        .load()
        .then((google) => {

            var city = new google.maps.LatLngBounds(
            new google.maps.LatLng(cityBounds.sw.lat,cityBounds.sw.lng),
            new google.maps.LatLng(cityBounds.ne.lat,cityBounds.ne.lng));

            const options = {
                componentRestrictions: { country: "in" },
                bounds: city,
                // location: {latitude:19.0453567,longitude:72.8379152},
                // radius: 50000,
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: true
            };

            console.log("OPT", options);
            autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.addListener('place_changed',makeHandler(autocomplete));
        })
        .catch(e => {
            // do something
        });

    }, [cityBounds]);


    const makeHandler = (autocomplete:google.maps.places.Autocomplete) => {

        return async function() {
            const place = autocomplete.getPlace();
            console.log("PLACE", place);
            var pincode: string = '';
            const input = document.getElementById(id) as HTMLInputElement;

            const addressComponents = place.address_components;
            if(addressComponents){
                for (let i = 0; i < addressComponents.length; i++) {
                    const typesArray = addressComponents[i].types
                    for (let j = 0; j < typesArray.length; j++) {
                        if(typesArray[j]==="postal_code")
                        pincode = addressComponents[i].long_name
                    }
                }
            }

            if(place!==undefined && place.geometry!==undefined){
            var placeData:any = {
                address: input.value,
                pincode: pincode,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };

            // await dispatch(setData(placeData));
            await setData(placeData);

            if(onSelect!==undefined){
                onSelect()
            }
            }
        }
    }


    return (
        <TextInput id={id} label={label} description={description?description:''} error={error} placeholder={placeholder} required onChange={(e)=>{if(e.target.value===""){clearData();}}} onMouseDown={e=>{if(onMouseDown){onMouseDown()}}} disabled={disabled || readonly} readOnly={readonly} className={className!==undefined?className:''} style={{width:'100%'}}>
        </TextInput>
    );
}