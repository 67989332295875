import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { signout, checkLogin } from '../firebase/auth';
import { UserData } from '../../assets/utility/dataTypes';
import { createUserAPI } from '../api/user';

export interface authState {
    fetching: boolean;
    isAuth: boolean;
    isReady: boolean;
    user: UserData | any;
}

const initialState: authState = {
    fetching: false,
    isAuth: false,
    isReady: false,
    user: undefined
}

export const checkLoginAction = createAsyncThunk(
    'user/checkLogin',
    async () => {
      const response = await checkLogin();
      // The value we return becomes the `fulfilled` action payload
      return response;

    }
);

export const signoutAction = createAsyncThunk(
    'user/signout',
    async () => {
      const response = await signout();
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const createUserAsync = createAsyncThunk(
    'user/createUser',
    async (userData:any) => {
      const response = await createUserAPI(userData);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        beReadyAction: (state) => {
            state.isReady = true;
        },
        setUser: (state, action) => {
            state.user = action.payload;
            state.isAuth = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkLoginAction.pending, (state) => {
                state.fetching = true;
            })
            .addCase(checkLoginAction.fulfilled, (state, action) => {
                console.log("RES", action);
                state.isAuth = true;
                state.fetching = false;
                // console.log("LOGIN", action.payload)
                state.user = action.payload;
            })
            .addCase(checkLoginAction.rejected, (state, action) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })

            .addCase(createUserAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createUserAsync.fulfilled, (state, action) => {
                console.log("RES", action);
                state.isAuth = true;
                state.fetching = false;
                state.user = action.payload.response.data.data;
            })
            .addCase(createUserAsync.rejected, (state, action) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })

            .addCase(signoutAction.pending, (state) => {
                state.fetching = true
            })
            .addCase(signoutAction.fulfilled, (state) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })
            .addCase(signoutAction.rejected, (state, action) => {
                console.log("RES", action);
                state.isAuth = false
                state.fetching = false
                state.user = undefined;
            })
    }
})

export const { beReadyAction, setUser } = authSlice.actions;

export const getAuth = (state: RootState) => state.auth.isAuth;
export const getAuthFetching = (state: RootState) => state.auth.fetching;

export const getReady = (state: RootState) => state.auth.isReady;
export const getUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;