import React, { useEffect, useLayoutEffect, useState } from "react";
import loadable from '@loadable/component';
import { Drawer } from '@mantine/core';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import cities from "./assets/utility/cityBounds.json"
import { getRandomInt } from "./assets/utility/util";

import { getReady,getAuth, checkLoginAction, beReadyAction, getUser } from "./app/reducer/auth.reducer";
import { getShowCart, getShowMenu, setDeliveryDetails, setActiveCity, setDeliveryPhoneNumber, setShowCart, setShowMenu, setDeliveryName, getProductsFromOutletIdAsync, getAllProductsAsync } from "./app/reducer/site.reducer";

import { useAppDispatch, useAppSelector } from './app/hooks';

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";

import Home from './pages/Home';
import Store from './pages/Store';
// import MyOrders from './pages/MyOrders';
// import Checkout from './pages/Checkout';
// import AboutUs from "./pages/AboutUs";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import TermsOfUse from "./pages/TermsOfUse";
// import PageNotFound from "./pages/PageNotFound";

import CartMenu from "./components/menus/CartMenu";
import MainMenu from "./components/menus/MainMenu";

import { POWERPLANTFOODS_LOGO } from "./assets";
import { unwrapResult } from "@reduxjs/toolkit";
import { UserData } from "./assets/utility/dataTypes";
import initalizeMessaging from "./app/firebase/messaging";
import { useNotifications } from "@mantine/notifications";
import { faFrown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllOrdersAsync, setActiveOrders } from "./app/reducer/orders.reducer";
import Welcome from "./components/home/Welcome";
import Product from "pages/Product";
import { Loader } from "@googlemaps/js-api-loader";

ReactGA.initialize('G-2WS231FT3D');

let GMAPS_APIKEY : string;
GMAPS_APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

declare global {
  interface Window {
    gmapsLoaded: boolean;
    loader: Loader;
  }
}

if (!window.gmapsLoaded) {
  console.log('GMAP LOADED');
  const loader = new Loader({
    apiKey: GMAPS_APIKEY,
    version: "weekly",
    libraries: ["places"]
  });
  window.loader = loader;
  window.gmapsLoaded = true;
}

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('846697723416504', undefined, options);

const Loading = () => {
  return <div style={{height:'100vh', width:'100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>loading...</div>;
}; 

const Affiliate = loadable(() => import("./pages/Affiliate"),{fallback: <Loading/>,});
const MyOrders = loadable(() => import("./pages/MyOrders"),{fallback: <Loading/>,});
const Checkout = loadable(() => import("./pages/Checkout"),{fallback: <Loading/>,});
const AboutUs = loadable(() => import("./pages/AboutUs"),{fallback: <Loading/>,});
const Profile = loadable(() => import("./pages/Profile"),{fallback: <Loading/>,});
const PrivacyPolicy = loadable(() => import("./pages/PrivacyPolicy"),{fallback: <Loading/>,});
const TermsOfUse = loadable(() => import("./pages/TermsOfUse"),{fallback: <Loading/>,});
const PageNotFound = loadable(() => import("./pages/PageNotFound"),{fallback: <Loading/>,});

const quotesArr = [
  "No artifical flavouring and preservatives beyond this point.",
  "No animals were harmed while making this webapp.",
  "Loading Plant-based health...",
  "Better for you. Better for our planet.",
  "Petting cats..."
]

// const ScrollToTopWithRouter = withRouter(ScrollToTop);

function SiteBanner(){
  let location = useLocation();
  const path = location.pathname;

  return(
    <div className="site-banner" style={{display:(path==="/home"?'none':'flex')}}>
      <div className="marquee">
        <div>Made-on demand | No preservatives or artificial flavours | Go zero waste with our refundable containers</div>
        <div>Made-on demand | No preservatives or artificial flavours | Go zero waste with our refundable containers</div>
        <div>Made-on demand | No preservatives or artificial flavours | Go zero waste with our refundable containers</div>
        <div>Made-on demand | No preservatives or artificial flavours | Go zero waste with our refundable containers</div>
      </div>
    </div> 
  )
}

function RequireAuth({ children }: { children: JSX.Element }) {

  const isAuth = useAppSelector(getAuth);
  let location = useLocation();

  if (isAuth!==true) {
    return <Navigate to="/store" state={{ from: location }} replace />;
  }

  return children;
}

const Wrapper = ({children}:{children:any}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: undefined
    });
  }, [location.pathname]);
  return children
} 

export function Site(){
  const [quoteIndex, setQuoteIndex] = useState<number>(getRandomInt(0,5));

  // const [showMenu, setShowMenu] = useState<boolean>(false);
  // const [showCart, setShowCart] = useState<boolean>(false);
  // const [showProduct, setShowProduct] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(getAuth);
  const user = useAppSelector(getUser);
  const ready = useAppSelector(getReady);

  const showMenu = useAppSelector(getShowMenu);
  const showCart = useAppSelector(getShowCart);

  const notifications = useNotifications();

  const onUserDetected = async (user: UserData) => {
    // initalizeMessaging().then((currentToken) => {
    //   if (currentToken) {
    //     console.log('Token: ',currentToken);
    //     // Send the token to your server and update the UI if necessary
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     notifications.showNotification({
    //       color:"yellow",
    //       icon: <FontAwesomeIcon icon={faInfoCircle}/>,
    //       title: 'Allow Notifications',
    //       message: 'Allow notifcations to get updates on your orders. We promise not to spam you.',
    //       autoClose: false
    //     })
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.error('An error occurred while retrieving token. ', err);
    //   // ...
    // });
    console.log("user: ",user);
    const { userId, name, phoneNumber, address, lat, lng, outlet, city, house, pincode} = user;

    dispatch(getAllOrdersAsync(userId))
    .then(unwrapResult).then((response:any)=>{
        const activeOrders: any[] = [];
        const ordersArr: any[] = response;
        ordersArr.forEach((order)=>{
          if(order.status!=='delivered' && order.status!=='cancelled' && order.status!=='failed'){
            activeOrders.push(order);
          }
        })
        dispatch(setActiveOrders(activeOrders));
    });

    if(outlet){
      await dispatch(getProductsFromOutletIdAsync(outlet));
    }else{
      notifications.showNotification({
        color:"yellow",
        icon: <FontAwesomeIcon icon={faFrown}/>,
        title: 'We do not deliver in your area.🙁',
        message: 'But we will inform you when we do!',
        autoClose: false
      })
      await dispatch(getAllProductsAsync());
    }

    await dispatch(setDeliveryName(name));
    await dispatch(setDeliveryPhoneNumber(phoneNumber));
    await dispatch(setDeliveryDetails({house, address, lat, lng, city, pincode}));

    cities.forEach(cityObj => {
      
      if(city === cityObj.cityString){
        dispatch(setActiveCity(cityObj));
      }

    });

    dispatch(beReadyAction());
    
  }

  const initiateSite = async () => {
      
    await dispatch(checkLoginAction())
    .then(unwrapResult).then((response:any)=>{
        console.log("response", response);
        onUserDetected(response);
    }).catch(()=>{
      // onUserNotDetected();
    dispatch(beReadyAction());
    });

  }
  
  useEffect(() => {
    if(window.location.pathname.includes('/my-orders')){
      
    }else{
      dispatch(beReadyAction());
    }
    initiateSite();
    
  }, []);


  return(
    <Router>
    {/* <Welcome /> */}
      <Wrapper>
        <SiteBanner/>
        {ready && <Header />}
          <main>
          {/* {
            !ready &&
            <LoadingOverlay visible={true} />
          } */}
          {
            ready?
            <Routes>
              <Route
                path="/my-orders"
                element={
                  <RequireAuth>
                    <MyOrders />
                  </RequireAuth>
                }
              />
              {/* <Route
                path="/my-profile"
                element={
                  <RequireAuth>
                    <ProtectedPage />
                  </RequireAuth>
                }
              /> */}
              {/* <Route
                path="/checkout"
                element={
                  <RequireAuth>
                    <Checkout />
                  </RequireAuth>
                }
              /> */}
              <Route path="/affiliate" element={<Affiliate />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsOfUse />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/my-profile" element={<Profile />} />
              <Route path="/store/:productQuery" element={<Product />} />
              <Route path="/store" element={<Store />} />
              {/* <Route path="/home" element={<Home />} /> */}
              <Route path="/" element={<Navigate to="/store" replace />} />
              <Route path="*" element={<PageNotFound/>} />
              {/* <PrivateRoute path="/my-orders" isAuth={isAuth} Component={MyOrders}></PrivateRoute>
              <PrivateRoute path="/checkout" isAuth={isAuth} Component={Checkout}></PrivateRoute>
              <Route exact path="/store"
              render={(props) =><Home {...props}/>} />
              <Route exact path="/"
              render={(props) =><Redirect to={{ pathname: '/store', state: { from: props.location } }} />} />
              <Route path="*"
              render={(props) =><PageNotFound {...props}/>} /> */}
            </Routes>
            :
            <div className="page-body" style={{display:'flex', position:'relative', minHeight:'90vh', justifyContent:'center', alignItems:'center'}}>
              
              <div className="spinner">
              </div>
              <img
                className="spinner-badge"
                style={{marginLeft:'-30px', marginTop:'-30px'}}
                src={POWERPLANTFOODS_LOGO}
                height="60px"
                alt="PowerPlant Foods logo"
              />
              <div style={{fontWeight:900, padding:'100px', textAlign:'center'}}>
                {quotesArr[quoteIndex]}
              </div>

            </div>
          }
          </main>
        {ready && <Footer/>}
        
        <Drawer
          opened={showMenu} position="right"
          withCloseButton={false} withOverlay={false} lockScroll={false}
          onClose={() => dispatch(setShowMenu(false))}
          padding="xs" size="xl"
          transitionTimingFunction="ease"
        >
          <MainMenu isAuth={isAuth} user={user}/>
        </Drawer>

        <Drawer
          opened={showCart} position="right"
          withCloseButton={false} withOverlay={false} lockScroll={false}
          onClose={() => dispatch(setShowCart(false))}
          padding="xs" size="xl"
          transitionTimingFunction="ease"
        >
          <CartMenu/>
        </Drawer>

        {/* <Affix position={{ bottom: 70, right: 20 }} zIndex={10}>
          <Transition transition="slide-up" mounted={scrollPos.y > 100}>
            {(transitionStyles) => (
              <Button
                variant="default" color="dark"
                leftIcon={<FontAwesomeIcon icon={faChevronUp} />}
                style={transitionStyles}
                onClick={() => setScrollPos({ y: 0 })}
              >
                SCROLL TO TOP
              </Button>
            )}
          </Transition>
        </Affix> */}
      </Wrapper>
    </Router>
    )

}
