import axios, { AxiosResponse } from "axios";


const baseUrl = process.env.REACT_APP_CLOUD_FUNCTIONS_ENDPOINT;
const baseUrlWithFunctionName = baseUrl+"/affiliate";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = baseUrl+"";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

export function createAffiliateUserAPI(userData:any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrlWithFunctionName}/create-user`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: {userData}
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export function getAffiliateOrdersAPI(userId:string) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseUrlWithFunctionName}/get-orders/${userId}`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

// export function getSingleOrderAPI(orderId:string) {
//     return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
//         axios({
//             url: `${baseUrlWithFunctionName}/get-single-orders/${orderId}`,
//             method: "GET",
//             headers: {
//                 Accept: "application/json"
//             }
//         })
//             .then((res) => {
//                 resolve({ response: res })
//             })
//             .catch((err) => {
//                 reject({ error: err.response.status })
//             });
//     });
// }