import { useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Navbar, Badge } from "react-bootstrap";
import { useWindowScroll } from '@mantine/hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCartShopping, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCart } from "../../app/reducer/products.reducer";
import { getAuth } from "../../app/reducer/auth.reducer";

import './header.css';
import { POWERPLANTFOODS_LOGO } from '../../assets';

import { setShowCart, setShowMenu } from "../../app/reducer/site.reducer";

export default function Header():JSX.Element {
  const [scrollPosition, scrollTo] = useWindowScroll();
  // const { reward, isAnimating } = useReward('rewardId', 'confetti');

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const cart = useAppSelector(getCart);
  const isAuth = useAppSelector(getAuth);

  return (
    <Navbar className={"header"+((scrollPosition.y)>49?" header-fixed":"")} expand="lg"
    onKeyDown={(e:any) => e.stopPropagation()}
    onClick={(e:any) => e.stopPropagation()}
    onFocus={(e:any) => e.stopPropagation()}
    onMouseOver={(e:any) => e.stopPropagation()}
    onChange={(e:any) => e.stopPropagation()}
    style={{display:((location.pathname.includes('/store'))?'block':'none')}}
    >
        <Container>
            <Navbar.Brand
            style={{cursor:'pointer'}}
            onMouseDown={()=>{
              navigate('/')
              // if(!isAnimating){
              //   reward();
              // }
            }}>
              <img
              src={POWERPLANTFOODS_LOGO}
              height="30px"
              alt="PowerPlant Foods logo"
              />
              {/* <div id="rewardId" style={{transform: 'rotate(135deg)'}}>&nbsp;</div> */}
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <form className="header-search" onSubmit={(e)=>{e.preventDefault()}} style={{visibility:'hidden'}}>
              <FontAwesomeIcon icon={faSearch} className="header-icon"/>
              <input
                type="text"
                placeholder="What are you looking for?"
                aria-label="Search"
              />
            </form>
            <div>

              &nbsp;
              
              <Button
                className="header-menu"
                size="lg" variant="ghost" style={{position:'relative',visibility:((location.pathname==='/checkout')?'hidden':'visible')}}
                onClick={()=>dispatch(setShowCart(true))}>
                  <FontAwesomeIcon className={(cart.length>0)?'blue-pulse':''} icon={faCartShopping} />
                  <Badge bg="mine" style={{visibility:((cart.length<=0 || location.pathname==='/checkout')?'hidden':'visible'),position:'absolute', top: '0px', right: '1px', transform: 'scale(0.8)', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center', height:'27px', width:'27px', backgroundColor:'#12b886', fontWeight:900}}>
                    {cart.length}
                  </Badge>
              </Button>
              
              <Button
                className="header-menu"
                size="lg" variant="ghost"
                onClick={()=>dispatch(setShowMenu(true))}>
                  <FontAwesomeIcon icon={isAuth?faUser:faBars}/>
              </Button>

            </div>

        </Container>

    </Navbar>
  )

}
