import { useState } from "react";
import { ContextModalProps } from "@mantine/modals";
import { Text, TextInput, Textarea, Button, NumberInput } from "@mantine/core";
import { useNotifications } from '@mantine/notifications';

import { createUserAsync, setUser } from "../../app/reducer/auth.reducer";

import { loginWithPhone, loginWithPhoneOTP, fetchUser } from "../../app/firebase/auth";

import './modals.css';
import PlacesAutocomplete from "../third-party/PlacesAutocmplete";
import { SubmitHandler, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckCircle, faExternalLinkAlt, faFrown, faKey, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getActiveCity, getAllProductsAsync, getProductsFromOutletIdAsync, setDeliveryDetails, setDeliveryName, setDeliveryPhoneNumber } from "../../app/reducer/site.reducer";

import { unwrapResult } from "@reduxjs/toolkit";


type NewUserInputs = {
  name: string,
  email:string,
  // city: string, //more cities after we operate in more locations
  house: string,
  address: string,
  pincode: string,
  lat: number,
  lng: number
};

const PHONE_REGEX = /^(\+91|\+91\-|0)?[789]\d{9}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PINCODE_REGEX = /^[1-9]{1}\d{2}\s?\d{3}$/;

export default function LoginModal({ context, id, innerProps }: ContextModalProps<{ callback: Function }>):JSX.Element {
  
  const [busy, setBusy] = useState<boolean>(false);
  const [signInState, setSignInState] = useState<'number'|'number-submitted'|'otp'|'otp-err'|'confirmed'|'new'>('number');

  const [phone, setPhone] = useState<string>('');
  const [phoneErr, setPhoneErr] = useState<boolean>(false);

  // const [otpTimestamp, setOtpTimestamp] = useState<number>(0);
  // const [currentTimestamp, setCurrentTimestamp] = useState<number>(0);
  const [otp, setOtp] = useState<string>('');
  const [otpErr, setOtpErr] = useState<boolean>(false);

  const [uid, setUid] = useState<string>('');

  const [area, setArea] = useState<string>('');
  const [areaErr, setAreaErr] = useState<boolean>(false);

  const { register, control, setValue, getValues, handleSubmit, formState: { errors } } = useForm<NewUserInputs>();

  const [agree, setAgree] = useState<boolean>(true);
  const [agreeErr, setAgreeErr] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const notifications = useNotifications();

  const activeCity = useAppSelector(getActiveCity);

  // useEffect(() => {

  //   const timer = setInterval(()=>{
  //     setCurrentTimestamp(Date.now());
  //   })
  //   return () => {
  //     clearInterval(timer);
  //   }
  // }, )

  const submitNumber = () => {

    if(PHONE_REGEX.test(phone)){
      setBusy(true);
      setSignInState('number-submitted');
      loginWithPhone("+91"+phone).then(async ()=>{
        setSignInState('otp');
        setBusy(false);
        }
      ).catch((err)=>{
        console.log("LOGIN ERR: ",err);
        setBusy(false);
        setSignInState('number');
        }
      )
    }else{
      setPhoneErr(true);
    }

  }

  // const resendOTP = () => {

  //   loginWithPhoneAgain("+91"+phone).then(async ()=>{

  //     }
  //   ).catch((err)=>{
  //     }
  //   )

  // }

  const submitOTP = () => {

    if(otp.length===6){
      setBusy(true);
      loginWithPhoneOTP(otp).then((res)=>{
        console.log("USER :",res);
        getUser(res.uid);
        }
      ).catch((err)=>{
        console.log("LOGIN ERR: ",err);
        setBusy(false);
        setSignInState('otp-err');
        }
      )
    }else{
      setOtpErr(true);
    }

  }

  // useEffect(() => {
    
  //   if(otp.length === 6 && busy===false){
  //     submitOTP()
  //   }

  // }, [otp])

  const getUser = async (uid: string) => {

    setUid(uid);

    await fetchUser(uid).then(async (user)=>{
      setSignInState('confirmed');
      console.log("FETCHU :", user);
      let userObj:any = user;
      dispatch(setUser({...userObj, uid: uid}))
      const { name, phoneNumber, house, address, lat, lng, outlet, city, pincode } = userObj;
      dispatch(setDeliveryName(name));
      dispatch(setDeliveryDetails({house, address, lat, lng, city, pincode}));
      dispatch(setDeliveryPhoneNumber(phoneNumber));
      await dispatch(getProductsFromOutletIdAsync(outlet));
      // dispatch(setActiveOutlet(outlet));
      // notifications.showNotification({
      //   color:"green",
      //   icon: <FontAwesomeIcon icon={faCheckCircle}/>,
      //   title: 'Successfully Logged in!🥳',
      //   message: 'Welcome back to Power Plant Foods!',
      //   autoClose: true
      // })
      if(innerProps.callback){
        innerProps.callback();
      }
      context.closeModal(id);
    }).catch((err)=>{
      console.log("FETCHU ERR :", err);
      setSignInState('new');
      }
    )
    setBusy(false);

  }

  // const selectActiveCity = (cityString:string) => {

  //   cities.forEach(city => {
      
  //     if(cityString===city.cityString){
  //       dispatch(setActiveCity(city));
  //     }

  //   });
  // }

  const onSubmit: SubmitHandler<NewUserInputs> = (data) => {
    console.log("FORM :", data);

    // if(!agree){
    //   setAgreeErr(true);
    // }

    if(!data.address || !area){
      setAreaErr(true);
    }

    if(!agree || (!data.address || !area)){
      return;
    }
    
    setBusy(true);
    const userData = {
      userId: uid,
      phoneNumber: phone,
      ...data,
      city: activeCity.cityString,
    }

    dispatch(createUserAsync(userData)).then(unwrapResult)
    .then(async (res)=>{
      dispatch(setUser(res.response.data.data));

      const { name, phoneNumber, address, lat, lng, outlet, house, city, pincode} = res.response.data.data;
      
      dispatch(setDeliveryName(name));
      dispatch(setDeliveryDetails({house, address, lat, lng, city, pincode}));
      dispatch(setDeliveryPhoneNumber(phoneNumber));
      if(outlet){
        notifications.showNotification({
          color:"green",
          icon: <FontAwesomeIcon icon={faCheckCircle}/>,
          title: 'Successfully Signed in!',
          message: 'One step closer to fresh vegan food!🥳',
          autoClose: true
        })
        await dispatch(getProductsFromOutletIdAsync(outlet));
      }else{
        notifications.showNotification({
          color:"yellow",
          icon: <FontAwesomeIcon icon={faFrown}/>,
          title: 'We do not deliver in your area.🙁',
          message: 'But we will inform you when we do!',
          autoClose: false
        })
        await dispatch(getAllProductsAsync());
      }

      // if(data.city==="mumbai"){
      // }else{
        // notifications.showNotification({
        //   color:"yellow",
        //   icon: <FontAwesomeIcon icon={faExclamationCircle}/>,
        //   title: 'We do not deliver in your city yet.🙁',
        //   message: 'But we will inform you when we do!',
        //   autoClose: false
        // })
      // }

      if(innerProps.callback){
        innerProps.callback();
      }

      context.closeModal(id);
      setBusy(false);
      //success
    }).catch((err)=>{
      notifications.showNotification({
        color:"red",
        icon: <FontAwesomeIcon icon={faFrown}/>,
        title: 'Something went wrong.',
        message: 'Uh-oh! Make sure you have are online and try again.',
        autoClose: false
      })
      setBusy(false);
    })
  };

  return (
    <>
      <hr/>

      {(signInState==="number" || signInState==="number-submitted") &&
        <div className="modal-wrapper">
        <form onSubmit={(e)=>{e.preventDefault()}}>
          <div className="modal-field">
          <NumberInput icon={<FontAwesomeIcon icon={faMobileAlt} />}
            label="Enter your phone number: " autoFocus={true}
            error={phoneErr?"Enter valid Number":undefined} hideControls
            value={isNaN(parseInt(phone))?undefined:parseInt(phone)} onChange={value=>setPhone(value+"")} onMouseDown={()=>{setPhoneErr(false)}}/>
          </div>
          <Button id='phone-signin-btn' disabled={busy} loading={busy} type="submit" fullWidth
          style={{visibility:(signInState==="number-submitted")?"hidden":"visible"}}
          onClick={()=>{
            // const currentEpoc = Date.now();
            // const otpEpoc = currentEpoc+60000;
            // setOtpTimestamp(otpEpoc);
            submitNumber();
          }}>
            {busy?"Sending OTP":"SEND OTP"}
          </Button>
          {signInState==="number-submitted" &&
          <Button style={{marginTop:'-40px'}} disabled={true} loading={true} type="submit" fullWidth>
            Sending OTP
          </Button>
          }
          </form>
        </div>
      }

      {(signInState==="otp" || signInState==='otp-err') &&
        <div className="modal-wrapper">
          <Text className="hyperlink" style={{textAlign:'start', fontWeight:600, position:'absolute', top:'65px', left:'10px'}}
          onMouseDown={()=>{setSignInState('number');setOtp('')}}>
            <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Back
          </Text>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <div className="modal-field" onMouseDown={()=>{setOtpErr(false);setSignInState('otp')}}>
              {/* <TextInput id="otp" className={otpErr?"input-error":""} value={otp} onChange={e=>setOtp(e.target.value)}/> */}
              <TextInput icon={<FontAwesomeIcon icon={faKey} />}
                label={`Enter OTP sent to +91${phone}:`} autoFocus={true}
                error={(otpErr || signInState==='otp-err')?(otpErr?"Enter 6 digits":"Incorrect OTP"):undefined}
                value={otp} onChange={e=>setOtp(e.target.value)}

                />
          </div>
          <Button type="submit" fullWidth
          onClick={()=>submitOTP()} disabled={busy} loading={busy}>
            {busy?"Submitting OTP":"SUBMIT OTP"}
          </Button>
          </form>
        </div>
      }

      {signInState==="confirmed" &&
        <div className="modal-field">
          <div className="modal-field">
          <Text size="sm">
            Logging In...
          </Text>
          </div>
        </div>
      }

      {signInState==="new" &&
        <div className="modal-field">
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-field">
          <Text size="md">
            Set up account:
          </Text>
          </div>

          <div className="modal-field">
          <TextInput label="Your Name: " placeholder="Anthony Gonsalves" required
            {...register("name", { required: true })}
            error={errors.name?"Name is required":undefined}
          />
          </div>

          <div className="modal-field">
          <TextInput label="Your Email: " placeholder="example@mail.com" required
            {...register("email", { required: true, pattern: EMAIL_REGEX })}
            error={errors.email?"Email is required":undefined}
          />
          </div>
          
          {/* <div className="modal-field">
            <TextInput type="tel" label="Phone Number:" readOnly={true} id="mobile" className={phoneErr?"input-error":""} style={{opacity:0.5}}
              value={phone} onChange={e=>setPhone(e.target.value)} onMouseDown={()=>{setPhoneErr(false)}}/>
          <div className="error-text">{phoneErr?"Enter valid Number":" "}</div>
          </div> */}

          {/* <div className="modal-field">
          <Controller
            control={control}
            name="city" rules={{ required: true }}
            render={({
              field: { onChange, value },
            }) => (
              <Select
                label="Your City:" required autoComplete="off"
                placeholder="Select city" value={'mumbai'} 
                readOnly
                data={[
                  { value: 'mumbai', label: 'Mumbai' },
                  { value: 'bengaluru', label: 'Bengaluru' },
                  { value: 'pune', label: 'Pune' },
                ]}
                error={errors.city?"Please select your city":undefined}
              />
            )}
          />
          </div> */}

          <div className="modal-field" onMouseDown={()=>setAreaErr(false)}>
            <PlacesAutocomplete id="login-places" label="Your Society Name:"
            description="(Select city first)" error={areaErr?"Please type & select your society from dropdown list":undefined}
            placeholder={"Ocean View Apartments"} value={area} disabled={activeCity===undefined}
            setData={(placeObj: { address: string; lat: number; lng: number; pincode: string; })=>{
              setValue("address",placeObj.address);
              setArea(placeObj.address);
              setValue("lat",placeObj.lat);
              setValue("lng",placeObj.lng);
              setValue("pincode",placeObj.pincode);
            }}/>
          {/* <div className="error-text">{areaErr?"Please Type & select your society from dropdown list":" "}</div> */}
          </div>

          <div className="modal-field">
          <TextInput label="Pincode :" placeholder="400016" required
            {...register("pincode", { required: true, pattern: PINCODE_REGEX })}
            error={errors.pincode?"Pincode is required":undefined}
          />
          </div>

          <div className="modal-field">
            <Textarea
              placeholder="Room no. 420,&#10;A-Wing"
              label="Room No, Wing, etc:"
              required
              {...register("house", { required: true })}
              error={errors.house?"This field is required":undefined}
            />
          </div>

          <div className="modal-field" style={{padding:'5px'}}>
            By continuing you agree with the
            &nbsp;<a href="/terms-and-conditions"  target="_blank" rel="noopener noreferrer" className="hyperlink">Terms and Conditions&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt}/></a>&nbsp;and
            &nbsp;<a href="/privacy-policy"  target="_blank" rel="noopener noreferrer" className="hyperlink">Privacy Policy&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt}/></a>.
          </div>
          <Button id='phone-signin-btn' disabled={busy} loading={busy}
          type="submit" style={{width:'100%'}}
          >
            {busy?"Creating Account":"CREATE ACCOUNT"}
          </Button>
          </form>
        </div>
      }

    </>
  
  );

}

