
import {useEffect,useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { faCartShopping, faLeaf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Affix, Button, Transition } from '@mantine/core';
import { useViewportSize } from "@mantine/hooks";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getActiveOutlet, getAllProducts, getAllProductsAsync, getAskedPincode, getProductsFromOutletIdAsync, setAskedPincode, setShowCart } from '../app/reducer/site.reducer';

import ProductsSection from '../components/products';
import HomeSwiper from '../components/home/HomeSwiper';
import { useModals } from '@mantine/modals';
import { getCart } from '../app/reducer/products.reducer';
import { unwrapResult } from '@reduxjs/toolkit';
import AllProducts from 'components/products/AllProducts';

function FloatingCartButton():JSX.Element {
  const [totalamt, setTotalAmt] = useState<number>(0);
  const [refundamt, setRefundAmt] = useState<number>(0);

  const cart = useAppSelector(getCart)
  const dispatch = useAppDispatch();

  let totalItems = 0;

  cart.forEach((item)=>{
    totalItems = totalItems + item.amountInCart
  });

  useEffect(() => {
  
    let totalTemp = 0;
    let refundTemp = 0;

    cart.forEach((cartItem)=>{
      
      const itemPrice = (
        cartItem.priceAfterDiscount?
        cartItem.priceAfterDiscount
        : cartItem.price) * cartItem.amountInCart;

      totalTemp = totalTemp + itemPrice;

      if(cartItem.deposit){
        refundTemp = refundTemp + (cartItem.deposit * cartItem.amountInCart);
      }

    })

    setTotalAmt(totalTemp);
    setRefundAmt(refundTemp);

  }, [cart]);

  return(
    <div className="primary-text mobile-only"
    style={{
      display: cart.length >0?"block":"none",
      alignItems:'center',
      position: 'fixed',
      bottom: 0, left: 0, right: 0,
      zIndex: 10,
      boxShadow: 'rgb(82 185 135 / 64%) 0px 4px 20px 11px',
      WebkitBoxShadow: 'rgb(82 185 135 / 64%) 0px 4px 20px 11px'
    }}>
      <Button
        color="teal"
        radius="xs" fullWidth size="lg"
        style={{padding: '5px 15px'}}
        onClick={()=>dispatch(setShowCart(true))}
        >
          <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
              <div style={{fontSize:'12pt'}}>
                {totalItems}&nbsp;{totalItems>1?"Items":"Item"} | ₹{((totalamt+refundamt)/100).toFixed(2)}
              </div>
              <div style={{fontSize:'10pt', opacity:0.8, marginTop:'5px'}}>
                {((totalamt+refundamt)>14900)?
                "Eligible for Free Delivery!"
                :
                "Order above ₹149 for free delivery"
                }
              </div>
            </div>
            <div style={{flex:1, width:'550px'}}>
              
            </div>
            <div style={{fontWeight:600}}>
            <FontAwesomeIcon icon={faCartShopping}/>&nbsp; VIEW CART
            </div>
          </div>
      </Button>
    </div>
  )
}

function FloatingCartButtonAffix():JSX.Element {

  const cart = useAppSelector(getCart)
  const dispatch = useAppDispatch();

  return(
    <Affix position={{ bottom: 20, right: 20 }} zIndex={10}>
      <Transition transition="slide-up" mounted={cart.length > 0}>
        {(transitionStyles) => (
          <Button className="pc-only"
            color="teal"
            leftIcon={<FontAwesomeIcon icon={faCartShopping} />}
            style={transitionStyles}
            onClick={()=>dispatch(setShowCart(true))}
          >
            VIEW CART
          </Button>
        )}
      </Transition>
    </Affix>
  )
}

export default function Store():JSX.Element {
  ReactGA.send({ hitType: "pageview", page: "/store" });
  ReactPixel.pageView();

  const products = useAppSelector(getAllProducts);
  const outlet = useAppSelector(getActiveOutlet);
  const { height, width } = useViewportSize();
  // const askedPincode = useAppSelector(getAskedPincode);

  const dispatch = useAppDispatch();
  // const modals = useModals();

  const initiateStore = async () => {

    // if(askedPincode){
    //   return;
    // };
    const localOutletId = localStorage.getItem("ppf_oultet_id")
    if(localOutletId){

      await dispatch(getProductsFromOutletIdAsync(localOutletId)).then(unwrapResult)
      .catch(()=>{
        // modals.openContextModal('location', {
        //   title: <div className="modal-title secondary-text">ENTER YOUR PINCODE:</div>,
        //   innerProps: {
        //     callback: ()=>{
        //       return;
        //     },
        //   },
        //   closeOnClickOutside: false,
        //   withCloseButton: false,
        //   closeOnEscape: false
        // });

        dispatch(getAllProductsAsync());
      });

    }else{

      // modals.openContextModal('location', {
      //   title: <div className="modal-title secondary-text">ENTER YOUR PINCODE:</div>,
      //   innerProps: {
      //     callback: ()=>{
      //       return;
      //     },
      //   },
      //   closeOnClickOutside: false,
      //   withCloseButton: false,
      //   closeOnEscape: false
      // });
    }
    // dispatch(setAskedPincode(true));

    dispatch(getAllProductsAsync());
    
  }

  useEffect(() => {

    if(products.length>0){
      return;
    }
    if(outlet && outlet.id){
      dispatch(getProductsFromOutletIdAsync(outlet.id));
    }else{
      initiateStore();
    }

  }, [])
  
  return (
    <div className="page-body">
      <section className="section">
{/* 
        <HomeSwiper /> */}
        
      </section>

      <HomeSwiper />

      <section className="section">

          <Container>

            
            <Row className="main-row">
            
            <Col className="product-col" sm={12} style={{padding: 0}}>
              {/* <HomeSwiper /> */}
              {/* <ProductsSection icon={faFire} title="Top Sellers"/> */}
              <ProductsSection reverse icon={faLeaf} title="Our Best Sellers" filterTag="best-seller" background="linear-gradient(138deg, rgba(255,255,255,1) 0%, rgba(92,179,255,1) 72%, rgba(92,179,255,1) 87%, rgba(9,141,255,1) 100%)"/>
            </Col>
            {/* 
            <Col sm={12} style={{padding: 0}}>
              <ProductsSection icon={faLeaf} title="Plant-Based & Protein Rich" filterTag="protein-rich" background="white"/>
              <ProductsSection icon={faLeaf} title="Plant-Based Sauces and Spreads" filterTag="spreads-and-sauces" background="white"/>
            </Col>

            <Col sm={12} style={{padding: 0}}>
              <ProductsSection icon={faLeaf} title="Plant-Based Probiotic Food" filterTag="probiotics" background="white"/>
              <ProductsSection icon={faLeaf} title="Fresh Plant-Based Beverages" filterTag="plant-beverages" background="white"/>
            </Col> */}

            <Col className="product-col" sm={12} style={{padding: 0}}>
              {width>991?
                <AllProducts icon={faLeaf} title="All Products" background="white"/>
                :
                <ProductsSection icon={faLeaf} title="All Products" background="white"/>
              }
            </Col>

            {/* <Col sm={12} style={{padding: 0}}>
              <ProductsSection icon={faLeaf} title="Plant-Based & Protein Rich" filterTag="plant-beverages" background="white"/>
            </Col>

            <Col sm={12} style={{padding: 0}}>
              <ProductsSection icon={faLeaf} title="Plant-Based Sauces and Spreads" filterTag="spreads-and-sauces" background="white"/>
            </Col>


            <Col sm={12} style={{padding: 0}}>
              <ProductsSection icon={faLeaf} title="Plant-Based Probiotic Food" filterTag="protein-rich" background="white"/>
            </Col>
              
            <Col sm={12} style={{padding: 0}}>
              <ProductsSection icon={faLeaf} title="Fresh Plant-Based Beverages" filterTag="plant-beverages" background="white"/>
            </Col> */}
              
              
            </Row>

          </Container>
          <FloatingCartButton />
          <FloatingCartButtonAffix/>
      </section>
    </div>
  );

}

// const mapStateToProps = ( state: IRootState, ownProps = {} ) => ({
//   auth: state.auth
// });

// export default connect(mapStateToProps)(Home)
