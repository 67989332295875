import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrderData } from '../../assets/utility/dataTypes';
import { getAllOrdersAPI, getSingleOrderAPI } from '../api/user';
import { RootState } from '../store';
// import { getAllOrders, getOrderDetails } from '../api/cart';

export interface OrderState {
  fetching: boolean;
  fetchingDetails: boolean;
  activeOrders: OrderData[];
  allOrders: OrderData[];
  orderDetails: any;
}
  
const initialState: OrderState = {
  fetching: false,
  fetchingDetails: false,
  activeOrders: [],
  allOrders: [],
  orderDetails: undefined
}

export const getAllOrdersAsync = createAsyncThunk(
  'site/getAllOrders',
  async (userId:string) => {
    const response = await getAllOrdersAPI(userId);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getSingleOrderAsync = createAsyncThunk(
    'site/getSingleOrder',
    async (orderId:string) => {
      const response = await getSingleOrderAPI(orderId);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
      setActiveOrders: (state, action) => {
            state.activeOrders = action.payload;
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrdersAsync.pending, (state) => {
            state.fetching = true;
            })
            .addCase(getAllOrdersAsync.fulfilled, (state, action) => {
            state.fetching = false;
            state.allOrders = action.payload.response.data.data.pastOrders;
            state.activeOrders = action.payload.response.data.data.activeOrders;
            })
            .addCase(getAllOrdersAsync.rejected, (state) => {
            state.fetching = false;
            })

            .addCase(getSingleOrderAsync.pending, (state) => {
            state.fetchingDetails = true;
            })
            .addCase(getSingleOrderAsync.fulfilled, (state, action) => {
            state.fetchingDetails = false;
            state.orderDetails = action.payload.response.data.data.orderDetails;
            })
            .addCase(getSingleOrderAsync.rejected, (state) => {
            state.fetchingDetails = false;
            })
    }
})

export const { setActiveOrders } = orderSlice.actions;

export const getOrdersFetching = (state: RootState) => state.orders.fetching;
export const getActiveOrders = (state: RootState) => state.orders.activeOrders;
export const getAllOrders = (state: RootState) => state.orders.allOrders;

export default orderSlice.reducer;