import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithPhoneNumber, signOut, RecaptchaVerifier } from "firebase/auth";

// import { app } from './service';
import { getDocument, createDocument } from "./firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
  
// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
// import { getAnalytics } from "firebase/analytics";

declare global {
    interface Window { 
        recaptchaVerifier: any; 
        confirmationResult: any;
    }
}

const auth = getAuth();
// connectAuthEmulator(auth, 'http://localhost:9099');

export function fetchUser(uid:string) {

  return new Promise((resolve, reject) => {

    getDocument('customers',uid)
    .then((user)=>{
        resolve(user);
      }
    ).catch((error)=>{
      reject(error)
      }
    )

  })

}

export function fetchAffiliateUser(uid:string) {

  return new Promise((resolve, reject) => {

    getDocument('affiliates',uid)
    .then((user)=>{
        resolve(user);
      }
    ).catch((error)=>{
      reject(error)
      }
    )

  })

}

export function createUser(uid:string, data:any) {

  return new Promise((resolve, reject) => {

    createDocument('customers',uid,data)
    .then((user)=>{
      resolve(user);
      }
    ).catch((err)=>{
        reject(err);
      }
    )

  })

}

export function editUser(uid:string, data:any) {

  return new Promise((resolve, reject) => {

    createDocument('customers',uid,data)
    .then((user)=>{
      resolve(user);
      }
    ).catch((err)=>{
        reject(err);
      }
    )

  })

}

export function loginWithPhone(number:string) {

  return new Promise<void>((resolve, reject) => {

    window.recaptchaVerifier = new RecaptchaVerifier('phone-signin-btn', {
      'size': 'invisible',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("CAPTCHA", response)
      }
    }, auth);

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, number, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        resolve();
        // ...
      }).catch((error) => {
        reject(error);
        // Error; SMS not sent
        // ...
      });
      // ...
    })
        
}

export function loginWithPhoneOTP(otp:string) {

  return new Promise<any>((resolve, reject) => {

    const code = otp;

      window.confirmationResult.confirm(code).then((result: any) => {
      // User signed in successfully.
      const user = result.user;
      resolve(user);
      console.log("PHONE USER", user);
      // ...
      }).catch((error:any) => {
        reject(error);
      // User couldn't sign in (bad verification code?)
      // ...
      })
      // ...
    })
        
}

export function checkLogin() {

  return new Promise(async (resolve, reject) => {

    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        console.log("LOGGED IN USER", user)
        await fetchUser(user.uid).then((res)=>{
          resolve(res);
          }
        ).catch((err)=>{
          reject(err);
          }
        );
        // ...
      } else {
        reject();
        // User is signed out
        // ...
      }
    });

  })
        
}

export function checkAffiliateLogin() {

  return new Promise(async (resolve, reject) => {

    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        console.log("LOGGED IN AFFILIATE USER", user)
        await fetchAffiliateUser(user.uid).then((res)=>{
          resolve(res);
          }
        ).catch((err)=>{
          reject(err);
          }
        );
        // ...
      } else {
        reject();
        // User is signed out
        // ...
      }
    });

  })
        
}

export function signout() {

  return new Promise<void>((resolve, reject) => {

    signOut(auth).then(() => {
      // Sign-out successful.
      resolve();
    }).catch((error) => {
      // An error happened.
      reject(error);
    });

  })
        
}
