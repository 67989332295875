import { faFacebookSquare, faInstagramSquare, faLinkedin, faPinterestSquare, faTwitterSquare, faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";

import './footer.css';
import { POWERPLANTFOODS_TRADEMARK } from "../../assets";

export default function Footer(none:{}):JSX.Element {
  return (
  <section className="footer">
    <Container>
        <Row>

          <Col  md={12}  lg={12}>
            <div className="footer-logo">  
              <img
                  src={POWERPLANTFOODS_TRADEMARK}
                  alt="Power Plant Foods logo"
              />
            </div>
          </Col>
      </Row>
      <Row>

        <Col  md={6}  lg={6}>
          <div className="footer-text footer-about-text">
          <b>Power Plant Foods</b> is a hyperlocal food company.<br/>We prepare sustainable and healthy plant-based food.
          </div>
        </Col>

        <Col  md={6}  lg={2}>
      
        </Col>
        
        <Col  md={6}  lg={4}>
          Get in touch: <br/>
          <b><a href = "mailto: hello@powerplantfoods.in">hello@powerplantfoods.in</a></b>
        </Col>

      </Row>

    </Container>
    <section>
      <Container>
        
      <Row>

        <Col  md={6}  lg={6}>
        <p className="footer-text footer-copyright-text">
            <span className="copyright-symbol">©&nbsp;</span>Copyright {new Date().getFullYear()} Sakura Biotech LLP. - All Rights Reserved
        </p>
        </Col>

        <Col  md={6}  lg={2}>
      
        </Col>
        
        <Col  md={6}  lg={4}>
          <div className="footer-social-media">
          <a href='https://wa.me/+917400314844?' target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faWhatsappSquare} /></a>
            <a href='https://www.instagram.com/powerplantfoods.in/' target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagramSquare} /></a>
            {/* <FontAwesomeIcon icon={faFacebookSquare} /> */}
            <a href='https://www.linkedin.com/company/sakura-biotech-llp/' target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
          </div>
        </Col>

      </Row>
      </Container>
    </section>
  </section>
  
  );
}