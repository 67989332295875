import React from 'react';
import './App.css';
import { Site } from './Site';

function App() {
  return (
    <div className="App">
      <Site />
    </div>
  );
}

export default App;
