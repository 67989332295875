import { faMinus, faPlus, faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button,Drawer,Tooltip } from "@mantine/core";
import { useState } from "react";
import { Card } from "react-bootstrap";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import { updateCart } from "../../app/reducer/products.reducer";
import ProductMenu from "../menus/ProductMenu";

import './products.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getActiveOutlet } from "../../app/reducer/site.reducer";
import { ProductData } from "../../assets/utility/dataTypes";


interface CartButtonProps{
  addOneProduct: Function;
  removeOneProduct: Function;
  createProduct: Function;
  deleteProduct: Function;
  amountInCart: number
}

export function CartButton({amountInCart,addOneProduct,removeOneProduct,createProduct,deleteProduct}:CartButtonProps):JSX.Element{

  if(amountInCart===0){
    return(
      <Button onClick={()=>createProduct()}  style={{padding:'0px', justifyContent:'center'}}>
        ADD TO CART
      </Button>
    )
  }else{
    return(
      <div className="qty-btn-wrapper" style={{padding:'0px'}}>
        
          <Button onClick={()=>removeOneProduct()} style={{borderTopRightRadius:'0px', borderBottomRightRadius:'0px'}}>
              <FontAwesomeIcon icon={faMinus}/>
          </Button><Tooltip style={{flex:1}} zIndex={9} label="Nice!" 
          position="bottom" withArrow opened={amountInCart===69}>
          <div className="qty-well">
            {amountInCart}
          </div></Tooltip>
          <Button 
            // disabled={amountInCart>4}
          onClick={()=>addOneProduct()} style={{borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}>
              <FontAwesomeIcon icon={faPlus}/>
          </Button>
      </div>
    )
  }

}

interface ProductProps{
  preview?:true;
  data: ProductData;
  amountInCart: number
}

export default function ProductTile({preview,data,amountInCart}:ProductProps):JSX.Element {
  const [showProductMenu, setShowProductMenu] = useState<boolean>(false);

  const dispatch = useAppDispatch()
  const { id, name, quantity, unit, description, price, rating, productQuery, priceAfterDiscount, discountPercent, deposit, } = data;
  const activeOutlet = useAppSelector(getActiveOutlet);

  const addOneProduct = () => {
    dispatch(updateCart(data,'addone'))
  }

  const removeOneProduct = () => {
    dispatch(updateCart(data,'removeone'))
  }

  const createProduct = () => {
    ReactGA.event({ category: "Products", action: "Added to cart", label: data.id});
    ReactPixel.track("Added to cart", {product: data.id});
    dispatch(updateCart(data,'create'))
    closeProduct();
  }

  const deleteProduct = () => {
    dispatch(updateCart(data,'delete'))
  }

  const openProduct = () => {
    setShowProductMenu(true);
    window.history.pushState("",name,"/store/"+productQuery);
    ReactGA.event({ category: "Products", action: "Viewed product info", label: data.id});
    ReactPixel.track("Viewed product info", {product: data.id});
    // ReactPixel.pageView();
  }

  const closeProduct = () => {
    setShowProductMenu(false);
    window.history.pushState("",name,"/store");
  }

  return(
      <Card className="product-card">
          {discountPercent &&
          <div
            className="product-offer-ribbon"
            onClick={e=>openProduct()}
          >
            {discountPercent}%<br/>Off
          </div>}
          <div
            className="product-img"
            style={{backgroundColor:'#ffffff66'}}
            onClick={e=>openProduct()}
          >
            <img
              // src={SOYMILK}
              src={`https://firebasestorage.googleapis.com/v0/b/ppf-webapp.appspot.com/o/product-images%2Fthumbnails%2F${id}_thumbnail.png?alt=media`}
              alt={"An image of "+name+". Property of Power Plant Foods."}
              style={{opacity:activeOutlet?((price!==0)?1:0.2):1}}
            />
          </div>
          <div className="product-data">
            <div className="product-text">
              <div className="product-title">
              {name}
              </div>
              <div className="product-subtitle">
              {quantity+unit}
              </div>
              {/* <div className="product-desc">
              {description}
              </div> */}
               {!preview && <div className="product-price">
               <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                  {
                  (price)?
                    (priceAfterDiscount) ?
                      <div style={{fontSize: '15pt'}}>₹{((deposit!==undefined?(deposit+priceAfterDiscount):priceAfterDiscount)/100).toFixed(2)}&nbsp;</div>
                      :
                      <div style={{fontSize: '15pt'}}>₹{((deposit!==undefined?(deposit+price):price)/100).toFixed(2)}&nbsp;</div>
                    :
                    <div style={{fontSize: '15pt', opacity:0.5}}>Out of stock</div>
                  }
                  {price!==0 && priceAfterDiscount!=undefined && <div style={{textDecoration:'line-through', opacity:0.5, fontSize: '10pt'}}>&nbsp;₹{((deposit!==undefined?(deposit+price):price)/100).toFixed(2)}&nbsp;</div>}
                </div>

                {/* {(price!==0 && deposit!==undefined) &&
                  <div className="product-deposit">
                    {"( includes +₹"+(deposit/100).toFixed(2)+" refundable deposit )"}
                  </div>
                } */}
                {/* {(price!==0 && deposit!==undefined) &&
                  <div className="product-deposit">
                    {"( includes refundable deposit )"}
                  </div>
                } */}
              </div>}
              {/* <div className="product-rating">
                {(rating<=0)? <FontAwesomeIcon icon={faStarEmpty}/> : ( (rating>=1)? <FontAwesomeIcon icon={faStar}/>:<FontAwesomeIcon icon={faStarHalfStroke}/> )}
                {(rating<=1)? <FontAwesomeIcon icon={faStarEmpty}/> : ( (rating>=2)? <FontAwesomeIcon icon={faStar}/>:<FontAwesomeIcon icon={faStarHalfStroke}/> )}
                {(rating<=2)? <FontAwesomeIcon icon={faStarEmpty}/> : ( (rating>=3)? <FontAwesomeIcon icon={faStar}/>:<FontAwesomeIcon icon={faStarHalfStroke}/> )}
                {(rating<=3)? <FontAwesomeIcon icon={faStarEmpty}/> : ( (rating>=4)? <FontAwesomeIcon icon={faStar}/>:<FontAwesomeIcon icon={faStarHalfStroke}/> )}
                {(rating<=4)? <FontAwesomeIcon icon={faStarEmpty}/> : ( (rating>=4.5)? <FontAwesomeIcon icon={faStar}/>:<FontAwesomeIcon icon={faStarHalfStroke}/> )}
              </div> */}
            </div>
          </div>
        {!preview && <div className="product-cta">
          <Button variant="outline" style={{padding:'0px', justifyContent:'center', backgroundColor:'#fff'}}
            onClick={e=>openProduct()}
          >
            VIEW MORE
          </Button>
          {price!==0?
            <CartButton
              amountInCart={amountInCart}
              addOneProduct={addOneProduct}
              removeOneProduct={removeOneProduct}
              createProduct={createProduct}
              deleteProduct={deleteProduct}
            />
            :
            <Button variant="outline" disabled style={{padding:'0px', justifyContent:'center', backgroundColor:'#fff'}}
            >
              {activeOutlet?"Out of Stock":"Unavailable"}
            </Button>
          }
        </div>}
        <Drawer
          opened={showProductMenu} position="left"
          withCloseButton={false} withOverlay={true} lockScroll={true}
          onClose={() => closeProduct()}
          padding="xs" size="xl"
          transitionTimingFunction="ease"
        >
          <ProductMenu
            data={data}
            handleClose={() => closeProduct()}
            amountInCart={amountInCart}
            addOneProduct={addOneProduct}
            removeOneProduct={removeOneProduct}
            createProduct={createProduct}
            deleteProduct={deleteProduct}
          />
        </Drawer>
      </Card>
  )

}