import { useState } from "react";
import { ContextModalProps } from "@mantine/modals";
import { Button, NumberInput } from "@mantine/core";
import ReactGA from 'react-ga4';

import './modals.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faSadTear } from "@fortawesome/free-solid-svg-icons";

import { getAllProductsAsync, getProductsFromOutletPincodeAsync } from "../../app/reducer/site.reducer";
import { useAppDispatch } from "../../app/hooks";
import { unwrapResult } from "@reduxjs/toolkit";

const PINCODE_REGEX = /^[1-9]{1}\d{2}\s?\d{3}$/;

export default function LocationModal({ context, id, innerProps }: ContextModalProps<{ callback: Function }>):JSX.Element {
  const [pincode, setPincode] = useState<number | undefined>(undefined);
  const [pincodeErr, setPincodeErr] = useState<boolean>(false);

  const [deliveryErr, setDeliveryErr] = useState<boolean>(false);

  const [busy, setBusy] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const submitPincode = () => {

    if(pincode){
      if(PINCODE_REGEX.test(pincode+"")){
        setBusy(true);
        ReactGA.event({ category: "Store", action: "Entered Pincode", label: pincode+""});
        dispatch(getProductsFromOutletPincodeAsync(pincode+"")).then(unwrapResult)
        .then((res:any)=>{
            localStorage.setItem("ppf_oultet_id", res.response.data.data.outletData.id);
            context.closeModal(id);
        }).catch(()=>{
            dispatch(getAllProductsAsync());
            setDeliveryErr(true);
            setBusy(false);
        });
        
      }else{
        setPincodeErr(true);
      }
    }
  }
  
  return (
    <>
      <hr/>
        {
          deliveryErr?
          <div className="modal-wrapper">
            <div className="modal-field secondary-text" style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
              <FontAwesomeIcon icon={faSadTear} style={{fontSize:'50px', marginBottom:'20px', opacity:0.3}}/>
              Uh oh, looks like your pincode does not fall into our delivery zones. You can always visit our outlets and grab something! 
            </div>
            <Button id='phone-signin-btn' disabled={busy} loading={busy}
              onClick={()=>{
                context.closeModal(id);
              }}>
                GOT IT
            </Button>
          </div>
          :
          <div className="modal-wrapper">
            <form onSubmit={(e)=>{e.preventDefault()}}>
              <div className="modal-field">
              <NumberInput hideControls icon={<FontAwesomeIcon icon={faLocationDot} />} label="Enter your pincode:" autoFocus={true}
                error={pincodeErr?"Enter a valid pincode":undefined}
                value={pincode} onChange={(value)=>setPincode(value)} onMouseDown={()=>{setPincodeErr(false)}}/>
              </div>
              <Button id='phone-signin-btn' loading={busy} fullWidth type="submit"
                onClick={()=>{
                  // const currentEpoc = Date.now();
                  // const otpEpoc = currentEpoc+60000;
                  // setOtpTimestamp(otpEpoc);
                  submitPincode();
                }}>
                {busy?"Confirming Pincode":"CONFIRM PINCODE"}
              </Button>
            </form>
          </div>
        }
    </>
  
  );

}

