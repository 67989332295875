import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';

import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';

import LoginModal from './components/modals/LoginModal';
import LocationModal from './components/modals/LocationModal';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider>
        <NotificationsProvider>
          <ModalsProvider modals={{ login: LoginModal, location: LocationModal }}>
            <App />
          </ModalsProvider>
        </NotificationsProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if(localStorage.getItem("ppf-version")==='revamp-1.111'){
  serviceWorkerRegistration.register();
}else{
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

