import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faUserCircle, faList, faBoxArchive, faUserTie, faUserAstronaut, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Badge, Avatar, Popover } from "@mantine/core";
import { useState } from "react";

import { faGlassBottle,faGlassJar } from "../../assets";

import './user.css';

function CreditsPopover() {
  const [opened, setOpened] = useState(false);
  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      target={<div style={{marginLeft: '10px', fontSize: '10pt', opacity:0.5, cursor:'pointer'}} onMouseDown={() => setOpened((o) => !o)}><FontAwesomeIcon icon={faQuestionCircle}/></div>}
      width={260}
      position="bottom"
      withArrow
    >
      <div style={{ textAlign:'center'}}>
        You can use <b>PPF Credits</b> to<br/>pay for item cost in order.
      </div>
    </Popover>
  );
}

interface UserCardProps{
    minimized?: boolean;
    user: any;
    profileBtnOnClick: Function;
    ordersBtnOnClick: Function;
}

export default function UserCard({user,minimized,profileBtnOnClick,ordersBtnOnClick}:UserCardProps):JSX.Element {
    const [expand, setExpand] = useState<boolean>(false);
    library.add(faGlassBottle);
    library.add(faGlassJar);

    const { name, pkgAtHome, type, ppfCredits } = user;
    const { bottle, jar, box } = pkgAtHome;

    let userIcon = faUser;
    let userType = "PPF User";

    switch (type) {
      case 'staff':
        userType = "PPF Staff"
        userIcon = faUserTie;
        break;
      case 'beta_tester':
        userType = "PPF Beta User"
        userIcon = faUserAstronaut;
        break;
    
      default:
        break;
    }

    return (
        <Card shadow="xl" className="user-card">
              <Card.Section className="user-card-header">
                <div className="user-card-section">
                  <Avatar className="icon" radius="xl" size="lg" color="light" style={{minWidth:'40px', width:'40px', height:'40px'}}>
                    <FontAwesomeIcon icon={userIcon}/>
                  </Avatar>
                  <div className="user-name">
                    {name}<br/>
                    <div className="user-title">{userType}</div>
                  </div>
                </div>
              </Card.Section>
              <Card.Section style={{height:'15px', backgroundColor: '#7cc2ff', opacity: 0.6}}/>
              <Card.Section className="user-card-body" style={{marginTop:'-15px'}}>
                <div className="user-card-section" style={{ paddingTop: '20px', paddingLeft: '20px'}}>
                  <div className="user-pkg">
                    <span style={{opacity:0.5}}>
                      PPF Credits:&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                     <Badge color="teal" className="user-pkg badge-item">
                     ₹{ppfCredits}&nbsp;<CreditsPopover />
                    </Badge>
                    
                  </div>
                </div>
                {/* <div className="user-card-section" style={{ paddingTop: '20px', paddingLeft: '20px'}}>
                </div> */}
                <div className="user-card-section" style={{justifyContent:'space-around', padding: '5px'}}>
                  <div className="user-pkg" style={{opacity:0.5}}>
                     Containers at your home:
                  </div>
                  <Badge color={(bottle===0)?"teal":((bottle>9)?"red":"yellow")} className="user-pkg badge-item">
                      <FontAwesomeIcon icon={faGlassBottle}/>&nbsp;&nbsp;{bottle}
                  </Badge>
                  <Badge color={(jar===0)?"teal":((jar>9)?"red":"yellow")}  className="user-pkg badge-item">
                      <FontAwesomeIcon icon={faGlassJar}/>&nbsp;&nbsp;{jar}
                  </Badge>
                </div>
                {/* <div className="user-card-section" style={{ paddingTop: '20px', paddingLeft: '20px'}}>
                </div> */}
                <div className="user-card-section" style={{justifyContent:'space-around', padding: '5px'}}>
                  <div className="user-pkg" style={{opacity:0.5}}>
                     Containers you returned:
                  </div>
                  <Badge color='blue' className="user-pkg badge-item">
                      <FontAwesomeIcon icon={faGlassBottle}/>&nbsp;&nbsp;{(user.pkgReturned && user.pkgReturned.bottle)?user.user.pkgReturned.bottle:'0'}
                  </Badge>
                  <Badge color='blue' className="user-pkg badge-item">
                      <FontAwesomeIcon icon={faGlassJar}/>&nbsp;&nbsp;{(user.pkgReturned && user.pkgReturned.jar)?user.user.pkgReturned.jar:'0'}
                  </Badge>
                </div>
              </Card.Section>
             {!minimized && <Card.Section className="user-card-body" style={{marginTop:'-15px'}}>
                <div className="user-card-section user-buttons">
                  <Button
                      style={{flex:1, marginRight:'5px'}}
                      leftIcon={<FontAwesomeIcon
                      icon={faUserCircle}/>}
                      onClick={()=>{
                          profileBtnOnClick()
                      }}>
                    MY PROFILE
                  </Button>
                  <Button
                      style={{flex:1, marginLeft:'5px'}}
                      leftIcon={<FontAwesomeIcon
                      icon={faList}/>}
                      onClick={()=>{
                          ordersBtnOnClick()
                      }}>
                    MY ORDERS
                  </Button>
                </div>
              </Card.Section>}
            </Card>
    );
}