import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { signout, checkAffiliateLogin } from '../firebase/auth';
import { UserData } from '../../assets/utility/dataTypes';
import { createAffiliateUserAPI, getAffiliateOrdersAPI } from 'app/api/affiliate';

export interface affiliateState {
    fetching: boolean;
    isAuth: boolean;
    isReady: boolean;
    user: UserData | any;
    orders: any[]
}

const initialState: affiliateState = {
    fetching: false,
    isAuth: false,
    isReady: false,
    user: undefined,
    orders: []    
}

export const checkAffiliateLoginAction = createAsyncThunk(
    'affiliate/checkAffiliateLogin',
    async () => {
      const response = await checkAffiliateLogin();
      // The value we return becomes the `fulfilled` action payload
      return response;

    }
);

export const signoutAction = createAsyncThunk(
    'affiliate/signout',
    async () => {
      const response = await signout();
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const createAffiliateUserAsync = createAsyncThunk(
    'affiliate/createAffiliateUser',
    async (userData:any) => {
      const response = await createAffiliateUserAPI(userData);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const getAffiliateOrdersAsync = createAsyncThunk(
    'affiliate/getAffiliateOrders',
    async (userId:string) => {
      const response = await getAffiliateOrdersAPI(userId);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const affiliateSlice = createSlice({
    name: 'affiliate',
    initialState,
    reducers: {
        beReadyAction: (state) => {
            state.isReady = true;
        },
        setAffiliateUser: (state, action) => {
            state.user = action.payload;
            state.isAuth = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkAffiliateLoginAction.pending, (state) => {
                state.fetching = true;
            })
            .addCase(checkAffiliateLoginAction.fulfilled, (state, action) => {
                console.log("RES", action);
                state.isAuth = true;
                state.fetching = false;
                // console.log("LOGIN", action.payload)
                state.user = action.payload;
            })
            .addCase(checkAffiliateLoginAction.rejected, (state, action) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })

            .addCase(createAffiliateUserAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(createAffiliateUserAsync.fulfilled, (state, action) => {
                state.isAuth = true;
                state.fetching = false;
                state.user = action.payload.response.data.data;
            })
            .addCase(createAffiliateUserAsync.rejected, (state, action) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })

            .addCase(getAffiliateOrdersAsync.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAffiliateOrdersAsync.fulfilled, (state, action) => {
                state.fetching = false;
                state.orders = action.payload.response.data.data;
            })
            .addCase(getAffiliateOrdersAsync.rejected, (state, action) => {
                state.fetching = false;
            })

            .addCase(signoutAction.pending, (state) => {
                state.fetching = true
            })
            .addCase(signoutAction.fulfilled, (state) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })
            .addCase(signoutAction.rejected, (state, action) => {
                console.log("RES", action);
                state.isAuth = false
                state.fetching = false
                state.user = undefined;
            })
    }
})

export const { beReadyAction, setAffiliateUser } = affiliateSlice.actions;

export const getAffiliateAuth = (state: RootState) => state.affiliate.isAuth;
export const getAffiliateAuthFetching = (state: RootState) => state.affiliate.fetching;

export const getAffiliateReady = (state: RootState) => state.affiliate.isReady;
export const getAffiliateUser = (state: RootState) => state.affiliate.user;
export const getAffiliateOrders = (state: RootState) => state.affiliate.orders;

export default affiliateSlice.reducer;