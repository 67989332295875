import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton } from "@mantine/core";
import { useRef } from "react";
import { Col, Card, Overlay, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateCart } from "../../app/reducer/products.reducer";
import { SOYMILK } from "../../assets";

import './products.css';

export default function ProductSkeleton():JSX.Element {
    
  return(
    <Card className="product-card">
      <div className="product-img">
      <Skeleton height={'100%'}/> 
      </div>
      <div className="product-data">
        <div className="product-text">
          <div className="product-title">
          <Skeleton height={15} mt="xs" mb="xs" width="100%" />  
          </div>
          <div className="product-subtitle">
          <Skeleton height={10} />  
          </div>
          <div className="product-price">
            <Skeleton height={19} width="100%"/>
          </div>
          <div className="product-rating">
            <Skeleton height={10} width="100%"/>
          </div>
        </div>
      </div>
        <div className="product-cta">
        <Skeleton height={33} mb="xs" width="100%"/>
        <Skeleton height={33} width="100%"/>
      </div>
    </Card>
  )

}